import { combineReducers } from 'redux';
import devicesReducer from './devices/devicesReducer';
import devicesGroupReducer from './devicesGroup/devicesGroupReducer';
import devicesRnPmReducer from './devicesRnPm/devicesRnPmReducer';

export default combineReducers({
  devicesReducer,
  devicesGroupReducer,
  devicesRnPmReducer,
});
