import styled from 'styled-components';
import React, { useState, useMemo } from 'react';
import { t } from 'src/utils/commonMethods';
import { useStoreState } from 'src/store/useStoreState';
import { TreeView, Button, Scrollbar } from '@veneer/core';
import { DeleteGroupModal } from 'src/components/organism';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { getTreeViewParentNodes } from 'src/utils/groupMethods';
import { InfiniteScrollTree } from 'src/components/molecule';

type TEditGroupTreeProps = {
  allDevicesGroupUuid: string;
  ungroupedGroupUuid: string;
  groupData: any[];
  selectedGroupsIdList: string[];
  setSelectedGroupsIdList: (e) => void;
  setSelectedGroupName: (e) => void;
  setActionCount: (e) => void;
  refreshEditGroupModalPage: () => void;
};

const EditGroupTree = (props: TEditGroupTreeProps) => {
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const { showDevicesGroupCUD } = useShellRootContext();
  const { devicesRnPmState } = useStoreState();
  const { collectionsDeleteRnPm } = devicesRnPmState;

  const nodes = getTreeViewParentNodes(
    props.groupData,
    props.allDevicesGroupUuid,
    props.ungroupedGroupUuid,
    true,
  );

  const getIdsNotInPredefinedGroup = (groupIds) => {
    const predefinedDeviceGroupId = [props.allDevicesGroupUuid, props.ungroupedGroupUuid, ''];
    return groupIds.filter((item) => !predefinedDeviceGroupId.includes(item))[0];
  };

  const removeDisabledLabel = (idArray) => {
    return idArray.map((id) => {
      if (id == props.allDevicesGroupUuid || id == props.ungroupedGroupUuid)
        id.replace('disable_group-label__', '');
    });
  };

  const handleChangeEvent = (event, id) => {
    const selectedDeviceGroup = id.filter((groupId) => groupId != '');
    removeDisabledLabel(selectedDeviceGroup);

    props.setSelectedGroupsIdList(selectedDeviceGroup);

    if (selectedDeviceGroup.length === 0) {
      props.setSelectedGroupName('');
    } else {
      const filteredGroupId = getIdsNotInPredefinedGroup(selectedDeviceGroup);
      const found = props.groupData.find((group) => group.id === filteredGroupId).name;
      props.setSelectedGroupName(found);
    }
    event.preventDefault();
  };

  const hasNoSelectedGroups = () => {
    return props.selectedGroupsIdList.length === 0;
  };

  const renderTreeView = (displayedNodes) => {
    // NOTE: The allGroup and unGroup nodes have prefix 'disable_edit-group-label__' to disable the checkbox.
    return (
      <TreeView
        id="edit-group-tree"
        data-testid="edit-group-tree"
        defaultExpandedNodes={['disable_edit-group-label__' + props.allDevicesGroupUuid]}
        defaultSelectedNodes={['']}
        nodes={displayedNodes}
        onChange={handleChangeEvent}
        nodeSelector="multiSelection"
        disableAutoCheck={false}
      />
    );
  };

  const renderButton = useMemo(() => {
    return (
      collectionsDeleteRnPm && (
        <ButtonArea>
          <Button
            data-testid="edit-group-tree-delete-button"
            aria-label="Secondary"
            appearance="secondary"
            disabled={hasNoSelectedGroups()}
            onClick={() => setShowDeleteGroupModal(true)}
          >
            {t('group.delete')}
          </Button>

          {showDeleteGroupModal && (
            <DeleteGroupModal
              showDeleteGroupModal={showDeleteGroupModal}
              allDevicesGroupUuid={props.allDevicesGroupUuid}
              selectedGroupsIdList={props.selectedGroupsIdList}
              setShowDeleteGroupModal={setShowDeleteGroupModal}
              setSelectedGroupsIdList={props.setSelectedGroupsIdList}
              setActionCount={props.setActionCount}
              refreshEditGroupModalPage={props.refreshEditGroupModalPage}
            />
          )}
        </ButtonArea>
      )
    );
  }, [props.selectedGroupsIdList, showDeleteGroupModal]);

  return (
    <Wrapper>
      <Header>
        <Title>{t('group.groups')}</Title>
        {showDevicesGroupCUD && renderButton}
      </Header>
      <Body>
        <Scrollbar
          customStyle={{
            height: '100%',
            overflow: 'auto',
            width: '350px',
          }}
        >
          <TreeViewWrapper id={'edit-group-tree__wrapper'}>
            <InfiniteScrollTree nodes={nodes} render={renderTreeView} id={'edit-group-tree'} />
          </TreeViewWrapper>
        </Scrollbar>
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 350px;
  min-width: 300px;
  margin-right: 24px;
  padding-right: 4px;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 36px;
`;

const Title = styled.div`
  padding-top: 10px;
  font-size: 20px;
  font-weight: bold;
`;

const ButtonArea = styled.div`
  > button {
    padding: 8px 20px;
    min-width: min-content;
    height: 36px;
  }
`;

const Body = styled.div`
  margin-right: 24px;
  margin-top: 12px;

  height: calc(100% - 48px);
`;

const TreeViewWrapper = styled.div`
  height: inherit;

  //ul ul {
  //  padding-left: 36px;
  //}

  li[id^='disable_edit-group-label__'] {
    > div:first-child {
      pointer-events: none;

      span {
        border-color: #dcdcdc;
        color: #adadad;
      }
    }
  }
`;

export default EditGroupTree;
