import { useSelector, RootStateOrAny } from 'react-redux';

export const useStoreState: any = () => {
  const devicesState = useSelector((state: RootStateOrAny) => state.devicesReducer);
  const devicesGroupState = useSelector((state: RootStateOrAny) => state.devicesGroupReducer);
  const devicesRnPmState = useSelector((state: RootStateOrAny) => state.devicesRnPmReducer);

  return {
    devicesState,
    devicesGroupState,
    devicesRnPmState,
  };
};
