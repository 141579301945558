import styled from 'styled-components';
import React from 'react';
import { TreeView } from '@veneer/core';
import { getLokalisedGroupLabel } from 'src/utils/groupMethods';

type TCreateGroupTreeProps = {
  groups: any;
  setSelectedGroup: (e) => void;
  ungroupedGroupUuid: string;
  allDevicesGroupUuid: string;
};

const CreateGroupTree = (props: TCreateGroupTreeProps) => {
  const createTreeViewNodes = (groupData) => {
    const nodes = groupData
      .filter(
        (group) => group.id === props.allDevicesGroupUuid && group.id !== props.ungroupedGroupUuid,
      )
      .map((group) => {
        return {
          id: group.id,
          label: getLokalisedGroupLabel(group.name),
          totalChildren:
            group.devices === -1 || group.devices === null ? '0' : group.devices.toString(),
        };
      });
    return nodes;
  };

  const renderTreeView = () => {
    const nodes = createTreeViewNodes(props.groups);
    return (
      <TreeView
        data-testid="create-group-tree"
        nodes={nodes}
        onChange={(e, id) => {
          props.setSelectedGroup(id);
        }}
        defaultSelectedNodes={[props.allDevicesGroupUuid]}
      />
    );
  };

  return <Wrapper>{renderTreeView()}</Wrapper>;
};

export const Wrapper = styled.div`
  li {
    div {
      margin: 0;
      padding: 6px 8px 6px 8px;
      white-space: nowrap;
    }
  }
`;

export default CreateGroupTree;
