import React, { useEffect, useMemo, useState } from 'react';
import { DeviceAppConfigTemplate } from 'src/components/template';
import useTableColumn from 'src/hooks/useTableColumn';
import { setTableColumns } from 'src/utils/commonMethods';
import { deviceCacheAPI } from 'src/api/deviceCache';
import { useStoreState } from 'src/store/useStoreState';
import {
  storeDeviceTableRefresh,
  storeTableData,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';
import { useDispatch } from 'react-redux';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { useSearch } from 'src/hooks/useSearch';
import { CommonLoading } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const DeviceAppConfigPage = () => {
  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { appName } = useShellRootContext();

  const dispatch = useDispatch();
  const { getDevices } = deviceCacheAPI(appName);
  const { devicesState, devicesRnPmState } = useStoreState();
  const { deviceTableRefresh, searchItem } = devicesState;

  const tableId = 'apps-table';
  const { columnConfig } = useTableColumn();
  const { triggerSearch } = useSearch(tableId, columnConfig);
  const { tableHandler } = useTableHandler();
  const enableRnPm =
    devicesRnPmState.contentsDevicesCountRnPm && devicesRnPmState.contentsCollectionsReadRnPm;

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!deviceTableRefresh) {
      return;
    }
    fetch();
  }, [deviceTableRefresh]);

  const fetch = () => {
    setIsFetching(true);
    apiGetTableResponse()
      .then(({ devices, isError }) => {
        setTableColumns(tableId, columnConfig);
        handlePageData(devices);
        if (isError) {
          setError(isError);
        } else {
          setError(undefined);
        }
      })
      .finally(() => {
        dispatch(storeDeviceTableRefresh(false));
        setIsFetching(false);
      });
  };

  const apiGetTableResponse = async () => {
    console.log(`[app-overview][devices] apiGet Table Response`);

    const { response, error } = await getDevices();

    if (error != undefined) {
      return { devices: [], isError: true };
    }

    return {
      devices: response,
      isError: false,
    };
  };

  const handlePageData = (data) => {
    let items;
    let count = data?.length;

    if (data !== undefined) {
      items = JSON.parse(JSON.stringify(data));
    }

    if (searchItem) {
      const validResult = triggerSearch(items, searchItem);
      if (validResult) {
        items = validResult.pagedItems;
        count = validResult.searchedItemsCount;
      }
    }

    const res = tableHandler.getTableData(items);
    dispatch(storeTableData(res));
    dispatch(storeTotalDeviceCountInGroup(count));
  };

  const enableRender = !isFetching && enableRnPm;

  const memoRenderComponent = useMemo(() => {
    if (enableRender) {
      console.log('[devices] render <DeviceforAppTableTemplate />');
      return <DeviceAppConfigTemplate error={error} columnConfig={columnConfig} />;
    } else {
      return <></>;
    }
  }, [enableRender]);

  return <>{isFetching ? <CommonLoading /> : <>{memoRenderComponent}</>}</>;
};

export default DeviceAppConfigPage;
