import styled from 'styled-components';
import React from 'react';
import { IconWarningAlt } from '@veneer/core';

type TWarningMsgProps = {
  description: string;
};

const WarningMsg = (props: TWarningMsgProps) => {
  return (
    <Wrapper data-testid="warning-msg">
      <IconWarningAlt filled color="darkOrange6" size={24} />
      <Description>{props.description}</Description>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  left: 0;
  top: 0;
  background: rgba(208, 103, 2, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const Description = styled.div`
  padding-left: 10px;
`;

export default WarningMsg;
