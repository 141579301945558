import React, { ReactElement, useEffect } from 'react';
import { useMemo, useRef, useState } from 'react';

type TInfiniteScroll = {
  nodes: any[];
  render: (nodes) => ReactElement;
  id?: string;
};

/* This is a Group TreeView with Infinite Scroll applied */
const InfiniteScrollTree = (props: TInfiniteScroll) => {
  const showGroupOffset = 100;
  const totalNodesCnt = props.nodes[0].nodes.length;
  const stopScrollLimit = useRef(0);
  const [cnt, setCount] = useState(0);
  const displayedNodes = useMemo(
    () => [
      {
        ...props.nodes[0],
        nodes: props.nodes[0].nodes.slice(0, cnt),
      },
    ],
    [cnt],
  );

  const options = {
    root: document.getElementById(`${props.id}__wrapper`),
    threshold: 0.1,
  };
  const callback = () => {
    if (stopScrollLimit.current > totalNodesCnt) {
      return;
    }
    stopScrollLimit.current = stopScrollLimit.current + showGroupOffset;
    setCount((prev) => prev + showGroupOffset);
  };
  const target = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);

    observer.observe(target.current);
    return () => observer.unobserve(target.current);
  }, []);

  return (
    <>
      {props.render(displayedNodes)}
      <div id={`${props.id}_scroll-detector`} ref={target} style={{ height: 1 }} />
    </>
  );
};

export default InfiniteScrollTree;
