import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { MfeLoader } from 'src/components/atom';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { storeIsFromDetails } from 'src/store/devices/action';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const SubMfeDeviceDetails = (props: any) => {
  const { devicesState } = useStoreState();
  const { isFromUnassigned } = devicesState;
  const { stack, shell, deviceDetailsMfe, localization, isFromApps, appUuid, AppBar } =
    useShellRootContext();
  const dispatch = useDispatch();
  const { devID } = useParams();

  useEffect(() => {
    dispatch(storeIsFromDetails(true));
  }, []);

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <div>
          <MfeLoader
            disableContainerPadding
            {...props}
            {...{
              component,
              type,
              stack,
              shell,
              localization,
              appUuid,
              isFromApps,
              isFromUnassigned,
              deviceID: devID,
              AppBar,
            }}
          />
        </div>
      );
    },
    [shell, stack, props],
  );

  const memoRenderSubMfe = useMemo(() => {
    console.log('deviceDetailsMfe', deviceDetailsMfe);
    return (
      <div className="subMfeDeviceDetails">
        <SubMfe
          type="ECPDeviceDetails"
          component={
            deviceDetailsMfe !== '' ? deviceDetailsMfe : '@jarvis/react-ecp-device-details'
          }
        />
      </div>
    );
  }, []);

  return <>{memoRenderSubMfe}</>;
};

SubMfeDeviceDetails.defaultProps = {
  shell: {},
  stack: null,
};

SubMfeDeviceDetails.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

export default SubMfeDeviceDetails;
