import { t, toTitleCase } from 'src/utils/commonMethods';

export const getGroupsText = (groups) => {
  if (groups.length === 0) {
    return '--';
  }
  return groups?.map((group) => toTitleCase(group.label)).join(', ');
};

export const getConnectionStateText = (value) => {
  switch (value) {
    case 'online':
      return t('table.online');
    case 'offline':
    default:
      return t('table.offline');
  }
};

export const getAppNames = (apps) => {
  const appNames = apps.map((app) => app.title);

  if (!apps || appNames.length == 0) {
    return '--';
  }
  if (appNames.length == 1) {
    return appNames[0];
  }
  return `${appNames[0]} & more ${appNames.length - 1}`;
};

export const getAssessmentText = (assessment) => {
  switch (assessment) {
    case 'highRisk':
      return t('table.high_risk');
    case 'mediumRisk':
      return t('table.medium_risk');
    case 'lowRisk':
      return t('table.low_risk');
    case 'passed':
      return t('table.passed');
    case 'unknown':
      return t('table.unknown');
    case 'notAssessed':
    default:
      return t('table.not_assessed');
  }
};
