import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { IconWarningAlt } from '@veneer/core';

const GroupErrorMsg = () => {
  return (
    <Wrapper data-testid="group-error-msg" className="group-error-msg">
      <IconBackground>
        <IconWrapper>
          <IconWarningAlt />
        </IconWrapper>
      </IconBackground>
      <ErrorText>{t('unable_to_load_data')}</ErrorText>
    </Wrapper>
  );
};

export default GroupErrorMsg;

const Wrapper = styled.div`
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const IconBackground = styled.div`
  background: rgba(33, 33, 33, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
`;

const ErrorText = styled.span`
  color: #404040;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Forma DJR Micro';
`;
