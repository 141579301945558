import React, { useEffect, useState } from 'react';
import { DeviceTable } from 'src/components/organism';
import styled from 'styled-components';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { TableContextualFooter } from 'src/components/molecule';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { ResizeObserver } from 'resize-observer';

type TDeviceforAppTemplateProps = {
  error: boolean;
  columnConfig: any;
};

const DeviceAppConfigTemplate = (props: TDeviceforAppTemplateProps) => {
  const { devicesState, devicesRnPmState } = useStoreState();
  const { tableHandler } = useTableHandler();
  const { reportsOwnerRnPm, contentsDevicesUpdateRnPm } = devicesRnPmState;
  const { tableData, selectedItems, deviceTableRefresh, totalDeviceCountInGroup } = devicesState;
  const { showDevicesTableCheckbox, showDevicesTableActionArea } = useShellRootContext();
  const [bodyWidth, setBodyWidth] = useState(0);

  const showCheckbox = showDevicesTableCheckbox ? 'multiSelection' : '';
  const showHeaderActionArea = showDevicesTableActionArea;
  const showExportAll = reportsOwnerRnPm;
  const showContextualFooterCondition = contentsDevicesUpdateRnPm && selectedItems.length > 0;

  const onResize = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const { width } = entry.contentRect;
      setBodyWidth(width);
    }
  });

  useEffect(() => {
    onResize.observe(document.querySelector('div[id="solutions-apps-overview-tabs"]'));
  }, []);

  return (
    <Wrapper data-testid={'device-for-app-template__wrapper'}>
      <DeviceTable
        error={props.error}
        loading={deviceTableRefresh}
        tableData={tableData}
        deviceCount={totalDeviceCountInGroup}
        showCheckbox={showCheckbox}
        showHeaderActionArea={showHeaderActionArea}
        showExportAll={showExportAll}
        columnConfig={props.columnConfig}
      />
      {showContextualFooterCondition && (
        <TableContextualFooter
          selectedItems={selectedItems}
          handleFooterCancel={tableHandler.handleFooterCancel}
          bodyWidth={bodyWidth}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;
`;

export default DeviceAppConfigTemplate;
