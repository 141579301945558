import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { TableRenderers } from 'src/components/molecule';
import {
  storePageSize,
  storeTableData,
  storeSearchItem,
  storeCurrentPage,
  storeTableFilter,
  storeSelectedItems,
  storeColumnContents,
  storeCfgApiRequestOptions,
  storeDeviceTableRefresh,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';

export const useTableHandler: any = () => {
  const { getTableRenderers } = TableRenderers();
  const { devicesState, devicesGroupState } = useStoreState();
  const {
    tableData,
    selectedItems,
    cfgApiRequestOptions,
    tableFilter,
    currentPage,
    totalDeviceCountInGroup,
  } = devicesState;
  const { mainGroupSelectedId, mainGroupData } = devicesGroupState;
  const dispatch = useDispatch();

  const getTableData = (devices) => {
    if (devices.length == 0) {
      return [];
    }
    const newTableData = _getNewTableData(devices);
    dispatch(storeColumnContents(_getFriendlyTextContents(newTableData)));
    if (!_existsPreselectedItems) {
      return newTableData;
    }

    if (_existsPreselectedItems) {
      return _getPreselectedItems(newTableData);
    }
  };

  const _existsPreselectedItems = () => {
    return selectedItems.length > 0;
  };

  /*
  @param devices: tableData
  */
  const getExportListData = (devices) => {
    if (devices.length > 0) {
      const newTableData = _getNewTableData(devices);
      return _getFriendlyTextContents(newTableData);
    }
    return [];
  };

  const getColumnContents = (devices) => {
    const newTableData = _getNewTableData(devices);
    return _getFriendlyTextContents(newTableData);
  };

  const _getNewTableData = (devices) => {
    return getTableRenderers(devices)?.map((obj, index) => {
      // Add uid and rowConfig for checkbox function.
      return { ...obj, uid: index, rowConfig: { ...obj.rowConfig, selected: false } };
    });
  };

  const handleFooterCancel = () => {
    const updatedDataArray = [...tableData];
    updatedDataArray.forEach((item, index) => {
      _updateArrayWithSelectedItems(updatedDataArray, index, false);
    });

    dispatch(storeTableData(updatedDataArray));
    dispatch(storeSelectedItems([]));
  };

  const handleSearch = (searchValue: string, isFromApps?) => {
    dispatch(storeSearchItem(searchValue));
    dispatch(storeCurrentPage(1));

    // without search
    if (searchValue === '') {
      const allDeviceOptions = { ...cfgApiRequestOptions, offset: 0 };
      delete allDeviceOptions.search;
      if (isFromApps) {
        dispatch(storeTotalDeviceCountInGroup(totalDeviceCountInGroup));
      } else {
        const originalTotalDeviceCount = mainGroupData.filter(
          (group) => group.id === mainGroupSelectedId,
        )[0].devices;
        dispatch(storeTotalDeviceCountInGroup(originalTotalDeviceCount));
      }
      dispatch(storeCfgApiRequestOptions(allDeviceOptions));
      dispatch(storeDeviceTableRefresh(true));
      return;
    }

    // with search
    dispatch(
      storeCfgApiRequestOptions({
        ...cfgApiRequestOptions,
        offset: 0,
        search: searchValue,
      }),
    );
    dispatch(storeDeviceTableRefresh(true));
  };

  const handlePageChange = (page) => {
    if (page == currentPage) {
      return;
    }

    dispatch(storeCurrentPage(page));
    dispatch(
      storeCfgApiRequestOptions({
        ...cfgApiRequestOptions,
        offset: page - 1,
      }),
    );
    dispatch(storeDeviceTableRefresh(true));
  };

  const handlePageSizeChange = (event, option) => {
    dispatch(storePageSize(option.value));
    dispatch(
      storeCfgApiRequestOptions({
        ...cfgApiRequestOptions,
        offset: 0,
        limit: option.value,
      }),
    );
    dispatch(storeDeviceTableRefresh(true));
  };

  const handleSort = (_, { id, type }) => {
    dispatch(
      storeCfgApiRequestOptions({
        ...cfgApiRequestOptions,
        sortBy: id,
        order: type,
      }),
    );
    dispatch(storeDeviceTableRefresh(true));
  };

  const handleSelect = (event, index) => {
    dispatch(storeTableData(_selectRow(event.target.checked, index)));
  };

  const handleSelectAllPageItems = (event) => {
    const handledSelectedItemsTableArray = _selectAllPageItems(event.target.checked);
    dispatch(storeTableData(_selectAllPageItems(event.target.checked)));

    const numberOfSelectedLength = handledSelectedItemsTableArray.filter(
      (data) => data.rowConfig.selected === true,
    ).length;

    if (numberOfSelectedLength === 0) {
      _removeSelectedItems();
      return;
    }

    _setAllSelectedData();
  };

  const handleFilterOption = (e: any) => {
    if (e == null) return;
    const _filterList = [e.target.name];

    let filterOptions, filterValue;

    //Get previous filter
    if (tableFilter == null) {
      filterOptions = new Map();
    } else {
      filterOptions = new Map(JSON.parse(tableFilter));
    }

    //Get new filter category and filter value
    const tableFilterCategory = e.target.name.split(':')[0];
    const tableFilterValue = e.target.name.split(':')[1];

    //Get previous filter values
    filterValue = filterOptions.get(tableFilterCategory);
    if (filterValue == undefined) {
      //If undefined, there is no previous filter value. so create new value
      filterValue = [tableFilterValue];
    } else {
      //If there are previous filter values. then check that already there is new filter value
      if (filterValue.includes(tableFilterValue)) {
        //If it is, it means that action is "removing filter". So remove filter value from previous filter values
        filterValue.splice(filterValue.indexOf(tableFilterValue), 1);
      } else {
        filterValue.push(tableFilterValue);
      } //If it is not, add new filter value
    }

    //Final filter values has no value, then delete category from previous filter category
    if (filterValue.length == 0) {
      filterOptions.delete(tableFilterCategory);
    } else {
      filterOptions.set(tableFilterCategory, filterValue);
    }

    //If there is no category, then set null to tableFilter
    dispatch(
      storeTableFilter(
        filterOptions.size == 0 ? null : JSON.stringify(Array.from(filterOptions.entries())),
      ),
    );

    const _items = _filterList.join(',').toLowerCase();
    dispatch(
      storeCfgApiRequestOptions({
        ...cfgApiRequestOptions,
        connectionState: _items,
      }),
    );
    dispatch(storeCurrentPage(1));
    dispatch(storeDeviceTableRefresh(true));
  };

  const handleFilterClearAll = () => {
    dispatch(
      storeCfgApiRequestOptions({
        ...cfgApiRequestOptions,
        connectionState: '',
      }),
    );
    dispatch(storeCurrentPage(1));
    dispatch(storeTableFilter(null));
    dispatch(storeDeviceTableRefresh(true));
  };

  const _getFriendlyTextContents = (tableData) => {
    const newTextContents = {};
    tableData?.forEach((obj) => {
      const currentTextContent = {};
      Object.entries(obj).forEach(([key, entry]) => {
        if (typeof entry !== 'object' || entry == null || entry['props'] === undefined) return;
        currentTextContent[`${key}`] = entry['props']?.value;
      });
      newTextContents[obj.objectUniqueKey] = currentTextContent;
    });
    return newTextContents;
  };

  const _getPreselectedItems = (getDeviceData) => {
    return getDeviceData.map((row) => {
      const newRow = row;
      const index = selectedItems.findIndex((p) => p.deviceId === newRow.deviceId);
      if (index > -1) newRow.rowConfig.selected = true;
      return newRow;
    });
  };

  // TODO: store action
  const _removeSelectedItems = () => {
    dispatch(storeSelectedItems([]));
    dispatch(storeSelectedItems(_getSelectedDevicesFromOtherPages()));
  };

  const _isAlreadySelected = (index) => index > -1;
  const _updateArrayWithSelectedItems = (updatedDataArray, indexOfPagedData, checked) => {
    updatedDataArray[indexOfPagedData] = {
      ...updatedDataArray[indexOfPagedData],
      rowConfig: {
        ...updatedDataArray[indexOfPagedData].rowConfig,
        selected: checked,
      },
    };
  };

  const _selectRow = (checked, id) => {
    const updatedDataArray = [...tableData];
    const preSelectedItem = [...selectedItems];
    const indexOfPagedData = updatedDataArray.findIndex((row) => row.uid === id);
    const indexOfSelectedData = preSelectedItem.findIndex(
      (row) => row.deviceId === updatedDataArray[indexOfPagedData].deviceId,
    );

    _updateArrayWithSelectedItems(updatedDataArray, indexOfPagedData, checked);

    if (_isAlreadySelected(indexOfSelectedData)) {
      const newVal = selectedItems.filter(
        (data) => data.deviceId !== updatedDataArray[indexOfPagedData].deviceId,
      );
      dispatch(storeSelectedItems(newVal));
    } else {
      const newVal = [...selectedItems, updatedDataArray[indexOfPagedData]];
      dispatch(storeSelectedItems(newVal));
    }
    return updatedDataArray;
  };

  const _selectAllPageItems = (checked) => {
    const updatedDataArray = [...tableData];

    updatedDataArray.map((row) => {
      const index = updatedDataArray.findIndex((p) => p.uid === row.uid);
      if (index > -1) _updateArrayWithSelectedItems(updatedDataArray, index, checked);
    });

    return updatedDataArray;
  };

  const _setAllSelectedData = () => {
    // find selected devices from other pages, cuz storedDevices only contain devices of current page (which is collection return)
    // insert tableStore from selectedData
    dispatch(storeSelectedItems([...tableData, ..._getSelectedDevicesFromOtherPages()]));
  };

  const _getSelectedDevicesFromOtherPages = () => {
    const storedDeviceIds = tableData.map((device) => device.deviceId);
    return selectedItems.filter((sd) => !storedDeviceIds.includes(sd.deviceId));
  };

  const tableHandler = {
    // handleSetTableData,
    handleSearch,
    handlePageSizeChange,
    handlePageChange,
    handleSelect,
    handleSort,
    handleSelectAllPageItems,
    handleFooterCancel,
    handleFilterOption,
    handleFilterClearAll,
    getColumnContents, // export list contents

    getTableData,
    getExportListData,
  };

  return { tableHandler };
};
