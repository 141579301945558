import { useShellRootContext } from 'src/contexts/ShellRoot';
import { t, checkIsFromSolutions } from 'src/utils/commonMethods';

const useTableColumn = () => {
  const { columns, customRelativePath, featureFlags, customInitialColumns, isFromApps } =
    useShellRootContext();
  const unSupportedColumn = ['policies'];
  const initialColumns = ['modelName', 'status', 'connectionState', 'serialNumber', 'wiredIPv4'];
  const column = (id, label, width, search?, required?, sortable?) => {
    return {
      id,
      label,
      width: width,
      search: search ?? false,
      required: required ?? false,
      sortable: sortable ?? false,
    };
  };

  const tableColumns: Array<any> = [
    column('modelName', t('table.model_name'), 200, true, true),
    column('status', t('table.status'), 130, true),
    column('apps', t('table.apps'), 130, true),
    column('connectionState', t('table.connectivity'), 130, true),
    column('serialNumber', t('table.serial_number'), 150, true),
    column('wiredIPv4', t('table.wired_IPv4'), 220),
    column('wiredIPv6', t('table.wired_IPv6'), 220),
    column('wiredMAC', t('table.wired_MAC'), 220),
    column('wiredHostname', t('table.wired_hostname'), 220),
    column('wifiIPv4', t('table.wireless_IPv4'), 220),
    column('wifiIPv6', t('table.wireless_IPv6'), 220),
    column('wifiMAC', t('table.wireless_MAC'), 220),
    column('wifiHostname', t('table.wireless_hostname'), 220),
    column('addedDate', t('table.added_data'), 220, true),
    column('assessment', t('table.assessment'), 180),
    column('assetNumber', t('table.asset_number'), 180),
    column('firmwareVersion', t('table.firmware_version'), 180, true),
    column('deviceName', t('table.device_name'), 180),
    column('group', t('table.group'), 180, true),
    column('lastSyncTime', t('table.last_synced'), 220, true),
    column('location', t('table.location'), 180, true),
    // column('policies', t('table.policies'), 180),
    // column('policyLastRun', t('table.policy_LastRun'), 180, true),
    ...(featureFlags.enableDeviceConnType
      ? [column('connectivityTypes', t('table.connectivity_types'), 180)]
      : []),
    { id: 'uid', label: 'ID', index: 'hidden', width: 10, sortable: false },
  ];

  const filteredColumnsFromSolutions = () => {
    return columns.filter((col) => !unSupportedColumn.includes(col));
  };

  const filteredCustomInitialColumns = () => {
    return customInitialColumns.filter((col) => !unSupportedColumn.includes(col));
  };

  const cfgInitialColumnsList = () => {
    if (checkIsFromSolutions(customRelativePath) && columns.length > 0) {
      return filteredColumnsFromSolutions();
    } else if (isFromApps && customInitialColumns.length > 0) {
      return filteredCustomInitialColumns();
    } else return initialColumns;
  };

  const cfgColumnsList = () => {
    let filteredColumns = [...tableColumns];

    if ((checkIsFromSolutions(customRelativePath) || isFromApps) && columns.length > 0) {
      filteredColumns = filteredColumns.filter((col) => columns.includes(col.id));
    }

    return filteredColumns;
  };

  return {
    columnConfig: {
      cfgInitialColumnsList,
      cfgColumnsList,
    },
  };
};

export default useTableColumn;
