import { SideBar } from '@veneer/core';
import React from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import styled from 'styled-components';
import { useStoreState } from 'src/store/useStoreState';
import { useDispatch } from 'react-redux';
import { storeShowSideBar } from 'src/store/devicesGroup/action';

const MainGroupSideBar = (props) => {
  const { devicesGroupState } = useStoreState();
  const { showSideBar } = devicesGroupState;
  const { isWex } = useShellRootContext();
  const dispatch = useDispatch();

  if (isWex)
    return (
      <SideBarWrapper data-testid={'main-group-side-bar-wrapper'}>
        <SideBar
          behavior="persistent"
          position="start"
          collapsable
          show={showSideBar}
          content={props.children}
          onClose={() => dispatch(storeShowSideBar(false))}
          onCollapse={() => dispatch(storeShowSideBar(false))}
          onExpand={() => dispatch(storeShowSideBar(true))}
          size={250 + 48} //48 means padding for Main Group Wrapper
        />
      </SideBarWrapper>
    );
  else return <>{props.children}</>;
};

const SideBarWrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 155px);
`;

export default MainGroupSideBar;
