import React, { useEffect, useState } from 'react';
import rootReducer from 'src/store';
import CustomThemeProvider from 'src/contexts/CustomThemeProvider/CustomThemeProvider';
import { App } from 'src';
import { Provider } from 'react-redux';
import { TShellProps } from 'src/types/TShellProps';
import { configureStore } from '@reduxjs/toolkit';
import { checkIsFromApps } from 'src/utils/commonMethods';
import { ShellRootProvider } from 'src/contexts/ShellRoot';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { currentStack, sessionStorageKey } from 'src/utils/constants';
import { ToastProvider, DirectionProvider, useToast } from '@veneer/core';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/core';

declare global {
  interface Window {
    Shell: TShellProps;
  }
}

declare const window: any;
declare const sessionStorage: any;

export default function Root(props) {
  const { v1 } = window.Shell as TShellProps;
  const namespace = '@jarvis/react-ecp-devices';
  sessionStorage.setItem(sessionStorageKey.serviceID, props.serviceID ?? '');
  const [LDProvider, setLDProvider] = useState(null);
  const [tenantId, setTenantId] = useState('');
  const [emotionCache, setEmotionCache] = useState<any>();
  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React);

  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  });

  const getLDProvider = async () => {
    let clientID = '';
    const CURRENT_STACK = currentStack;

    if (CURRENT_STACK.LOCAL) clientID = '6232996cff5a0714a84243fc';
    else if (CURRENT_STACK.PIE) clientID = '6232996cff5a0714a84243ff';
    else if (CURRENT_STACK.STAGE) clientID = '6232996c7927321456ef8f7f';
    else {
      //production
      clientID = '6232996c7927321456ef8f82';
    }

    const Provider = await asyncWithLDProvider({
      clientSideID: clientID,
    });
    return Provider;
  };

  useEffect(() => {
    const setProvider = async () => {
      const Provider = await getLDProvider();

      setLDProvider(() => Provider);
    };

    setProvider();
    const tenantIdsMap = v1.tenantHandler.getTenantIdsMap();
    if (tenantIdsMap != undefined) {
      if (tenantIdsMap.stratusCustomer != undefined) {
        setTenantId(tenantIdsMap.stratusOrganization + '__' + tenantIdsMap.stratusCustomer);
      } else {
        setTenantId(tenantIdsMap.stratusOrganization);
      }
    }

    const newEmotionCache = createCache({
      container: document.head,
      key: 'css',
      speedy: false,
    });
    setEmotionCache(newEmotionCache);
  }, []);

  return LDProvider && emotionCache ? (
    <section id={namespace}>
      <DirectionProvider direction={directionValue}>
        <ToastProvider>
          <LDProvider>
            <ShellRootProvider
              ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
              stack={props.stack}
              shell={props.shell}
              localization={v1.localization}
              useToast={useToast}
              events={v1.events}
              authProvider={v1.authProvider}
              navigation={v1.navigation}
              getBreadcrumb={v1.breadcrumbs.getBreadcrumbs}
              setBreadcrumb={v1.breadcrumbs.add}
              removeBreadcrumb={v1.breadcrumbs.remove}
              showDevicesGroupCUD={props.showDevicesGroupCUD ?? true}
              showDevicesTableCheckbox={props.showDevicesTableCheckbox ?? true}
              showDevicesTableActionArea={props.showDevicesTableActionArea ?? true}
              showDevicesGroupView={props.showDevicesGroupView ?? true}
              showDevicesMainHeader={props.showDevicesMainHeader ?? true}
              columns={props.columns ?? []}
              customInitialColumns={props.customInitialColumns ?? []}
              deviceDetailsComponent={props.deviceDetailsComponent ?? null}
              deviceDetailsMfe={props.deviceDetailsMfe ?? ''}
              customRelativePath={props.customRelativePath ?? '/devices'}
              featureFlags={null}
              tenantId={tenantId}
              tenantName={v1.tenantHandlerInterface.getTenantData().name}
              accessControl={v1.accessControl}
              appName={props.appName ?? ''}
              appUuid={props.appUuid}
              theme={v1.theme}
              isWex={props.isWex ?? false}
              mode={props.configPath?.theme.mode ?? 'light'}
              setShowAddDeviceModal={props.setShowAddDeviceModal}
              setAddDeviceModalTitle={props.setAddDeviceModalTitle}
              setAddDeviceFooterButtonName={props.setAddDeviceFooterButtonName}
              isFromApps={checkIsFromApps(props.customRelativePath ?? '-') ?? false}
              AppBar={props.AppBar}
            >
              <CacheProvider value={emotionCache}>
                <CustomThemeProvider>
                  <Provider store={store}>
                    <App {...props} />
                  </Provider>
                </CustomThemeProvider>
              </CacheProvider>
            </ShellRootProvider>
          </LDProvider>
        </ToastProvider>
      </DirectionProvider>
    </section>
  ) : (
    <></>
  );
}
