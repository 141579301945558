import { service } from 'src/utils/constants';
import { getProps } from 'src/utils/commonMethods';
import {
  Stack,
  CollectionClient,
  DeviceCacheClient,
  ProgramMgtSvcClient,
  SubscriptionsClient,
  AccountMgtSvcClient,
  AppDeployClient,
} from '@jarvis/react-mfe-component/dist/src';

export const useApiClient = () => {
  const localProps = getProps();
  const stack = localProps.stack;
  const authProvider = localProps.authProvider;

  const getStackPrefix = (stack: Stack): string => {
    switch (stack) {
      case Stack.dev:
        return 'dev';
      case Stack.pie:
        return 'pie';
      case Stack.stage:
        return 'stage';
      case Stack.prod:
        return '';
      default:
        return '';
    }
  };

  const getStackPrefixSubscription = (stack: Stack): string => {
    switch (stack) {
      case Stack.dev:
      case Stack.pie:
        return 'daily';
      case Stack.stage:
        return 'staging';
      case Stack.prod:
        return '';
      default:
        return '';
    }
  };

  const getApiUrl = (type: string, hostPrefix: string, basePath: string): string => {
    let stackPrefix;
    let defaultBaseUrl;
    switch (type) {
      case 'appDeploy':
        defaultBaseUrl = 'tropos-rnd.com';
        stackPrefix = '';
        break;
      case 'subscription':
        defaultBaseUrl = 'cc-gw.hpcloud.hp.com';
        stackPrefix = getStackPrefixSubscription(stack);
        break;
    }
    return (
      'https://' +
      (hostPrefix.length ? hostPrefix + '.' : '') +
      (stackPrefix.length ? stackPrefix + '-' : '') +
      defaultBaseUrl +
      basePath
    );
  };

  const getApiClient = (serviceName) => {
    let client;

    switch (serviceName) {
      case service.accountMgt:
        client = new AccountMgtSvcClient(stack, authProvider);
        break;

      case service.appDeploy:
        const appDeployApiEndPoint = getApiUrl(
          'appDeploy',
          `stratus-${getStackPrefix(stack)}`,
          '/app-deploy-api/v1',
        );
        client = new AppDeployClient(appDeployApiEndPoint, authProvider);
        break;

      case service.deviceCache:
        client = new DeviceCacheClient(stack, authProvider);
        break;

      case service.collection:
        client = new CollectionClient(stack, authProvider);
        break;

      case service.programMgt:
        client = new ProgramMgtSvcClient(stack, authProvider);
        break;

      case service.subscriptions:
        const subscriptionsApiEndPoint = getApiUrl('subscription', '', '/cors/v1/subscriptions');
        client = new SubscriptionsClient(subscriptionsApiEndPoint, authProvider);
        break;
    }

    return client;
  };

  return {
    getApiClient,
  };
};
