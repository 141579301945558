import styled from 'styled-components';
import React from 'react';
import { t } from 'src/utils/commonMethods';
import { Button, IconDownload, IconEllipsis } from '@veneer/core';
import { ResponsiveWrapper } from 'src/components/atom/index';

type TExportAllButtonProps = {
  disabled: boolean;
  setShowModal: (e: boolean) => void;
};

const TableExportAllButton = (props: TExportAllButtonProps) => {
  const MobileButton = () => {
    return (
      <Button
        data-testid="export-device-button"
        id="export-btn__mobile"
        onClick={() => props.setShowModal(true)}
        disabled={props.disabled}
        appearance="secondary"
        leadingIcon={<IconEllipsis />}
        customStyle={{ width: '36px', minWidth: '36px', minHeight: '36px' }}
      />
    );
  };

  return (
    <ResponsiveWrapper tablet={<></>} mobile={<MobileButton />}>
      <ButtonWrapper>
        <Button
          data-testid="export-device-button"
          id="export-btn"
          onClick={() => props.setShowModal(true)}
          disabled={props.disabled}
          appearance="secondary"
          leadingIcon={<IconDownload />}
          customStyle={{
            ...{ minWidth: '129px', minHeight: '36px' },
            // ...(isWex ? { borderRadius: '360px' } : {}),
          }}
        >
          <Label>{t('table.export_all')}</Label>
        </Button>
      </ButtonWrapper>
    </ResponsiveWrapper>
  );
};

const Label = styled.span`
  margin-left: 5px;
`;

const ButtonWrapper = styled.div`
  margin-right: 0;
`;

export default TableExportAllButton;
