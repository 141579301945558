import styled from 'styled-components';
import React from 'react';
import tokens from '@veneer/tokens';
import { t } from 'src/utils/commonMethods';
import { Button } from '@veneer/core';

type TTableLeftSelectedItemProps = {
  selectedItems: any;
  handleFooterCancel: () => void;
};

const TableLeftSelectedItem = (props: TTableLeftSelectedItemProps) => {
  const selectedDataLength = props.selectedItems.length;
  return (
    <>
      <CancelButton
        id="contextual-footer-cancel-button"
        appearance="secondary"
        onClick={props.handleFooterCancel}
        data-testid="contextual-footer-cancel-button"
      >
        {t('table.cancel')}
      </CancelButton>

      <ItemsSelectedBox>
        <label data-testid="contextual-footer-selected-label">
          {selectedDataLength}{' '}
          {selectedDataLength > 1 ? t('table.items_selected') : t('table.item_selected')}
        </label>
      </ItemsSelectedBox>
    </>
  );
};

const CancelButton = styled(Button)`
  margin-right: 16px !important;
`;

const ItemsSelectedBox = styled.div`
  display: inline-flex;
  flex: 1 1 100%;
  order: 1;

  font-family: var(--fontRamilyRegular);
  font-size: ${tokens.typography.size2};
  line-height: ${tokens.typography.lineHeight3};
  vertical-align: middle;
`;

export default TableLeftSelectedItem;
