import React from 'react';
import { useResponsive } from 'src/hooks/useResponsive';

type TResponsiveWrapperProps = {
  children: React.ReactNode;
  tablet?: React.ReactNode;
  mobile?: React.ReactNode;
};

const ResponsiveWrapper = (props: TResponsiveWrapperProps) => {
  const { isPC, isTablet, isMobile } = useResponsive();

  if (isPC) {
    return <>{props.children}</>;
  }
  if (isTablet) {
    return <>{props.tablet || props.children}</>;
  }
  if (isMobile) {
    return <>{props.mobile || props.children}</>;
  }

  return null;
};

export default ResponsiveWrapper;
