import React from 'react';
import ShellRootContext from './ShellRootContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Stack } from '@jarvis/react-mfe-component/dist/src';

export const ShellRootProvider = (props) => {
  const flags = useFlags();

  return (
    <ShellRootContext.Provider
      value={{
        ecpDeviceV55: props.ecpDeviceV55,
        stack: props.stack ?? Stack.pie,
        shell: props.shell,
        localization: props.localization,
        events: props.events,
        useToast: props.useToast(),
        authProvider: props.authProvider,
        navigation: props.navigation,
        getBreadcrumb: props.getBreadcrumb,
        setBreadcrumb: props.setBreadcrumb,
        removeBreadcrumb: props.removeBreadcrumb,
        showDevicesGroupCUD: props.showDevicesGroupCUD,
        showDevicesTableCheckbox: props.showDevicesTableCheckbox,
        showDevicesTableActionArea: props.showDevicesTableActionArea,
        showDevicesGroupView: props.showDevicesGroupView,
        showDevicesMainHeader: props.showDevicesMainHeader,
        columns: props.columns,
        customInitialColumns: props.customInitialColumns,
        deviceDetailsComponent: props.deviceDetailsComponent,
        deviceDetailsMfe: props.deviceDetailsMfe,
        customRelativePath: props.customRelativePath,
        featureFlags: flags,
        tenantId: props.tenantId ?? '',
        tenantName: props.tenantName ?? '',
        accessControl: props.accessControl,
        appName: props.appName,
        appUuid: props.appUuid,
        theme: props.theme,
        isWex: props.isWex,
        mode: props.mode,
        setShowAddDeviceModal: props.setShowAddDeviceModal ?? undefined,
        setAddDeviceModalTitle: props.setAddDeviceModalTitle ?? undefined,
        setAddDeviceFooterButtonName: props.setAddDeviceFooterButtonName ?? undefined,
        isFromApps: props.isFromApps,
        AppBar: props.AppBar,
      }}
    >
      {props.children}
    </ShellRootContext.Provider>
  );
};
