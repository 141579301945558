import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';

export const accountMgtAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.accountMgt);

  const deleteDevice = async (accountId, cloudId) => {
    let response, error;

    await client
      .deleteDevice(accountId, cloudId)
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    deleteDevice,
  };
};
