import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { useStoreState } from 'src/store/useStoreState';
import { CreateGroupModal } from 'src/components/organism/index';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { Button, IconPencil, IconPlus } from '@veneer/core';

type TMainGroupButtonProps = {
  disabled?: boolean;
  setShowEditGroupModal: (boolean) => void;
};

const MainGroupButton = (props: TMainGroupButtonProps) => {
  const { showDevicesGroupView, showDevicesGroupCUD } = useShellRootContext();

  const { devicesRnPmState } = useStoreState();

  const { collectionsCreateRnPm, contentsDevicesCreateRnPm, contentsCollectionsCreateRnPm } =
    devicesRnPmState;

  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);

  return (
    <ButtonArea data-testid={'main-group-button-area'} disabled={props.disabled}>
      {collectionsCreateRnPm && contentsDevicesCreateRnPm && contentsCollectionsCreateRnPm && (
        <>
          <Button
            aria-label="Secondary"
            leadingIcon={<IconPlus />}
            appearance="secondary"
            onClick={() => setShowCreateGroupModal(true)}
            data-testid="create-group-button"
          />

          {showCreateGroupModal && (
            <CreateGroupModal
              showCreateGroupModal={showCreateGroupModal}
              setShowCreateGroupModal={setShowCreateGroupModal}
            />
          )}
        </>
      )}

      {showDevicesGroupView && showDevicesGroupCUD && (
        <Button
          aria-label="Secondary"
          leadingIcon={<IconPencil />}
          appearance="secondary"
          onClick={() => {
            props.setShowEditGroupModal(true);
          }}
          data-testid="edit-group-button"
          style={{ marginLeft: '10px' }}
        />
      )}
    </ButtonArea>
  );
};

const ButtonArea = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: center;
  > button {
    width: 36px;
    height: 36px;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;

      button {
        color: #adadad;
        border: 1px solid #adadad;
      }
    `}
`;

export default MainGroupButton;
