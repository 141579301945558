import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type TPopupWindowProps = {
  setOpenNewWindow: (open: boolean) => void;
  children: React.ReactNode;
  title: string;
  id: string;
  status: string;
  close?: boolean;
  setClose?: (close: boolean) => void;
};

const PopupWindow = (props: TPopupWindowProps) => {
  const windowRef = useRef<Window>(null);
  const [container, setContainer] = useState<HTMLDivElement>();

  const handlePageUnload = () => {
    // if (windowRef.current.confirm('Do you really want to leave?')) {
    //   props.setOpenNewWindow(false);
    // }
    props.setOpenNewWindow(false);
  };

  useEffect(() => {
    if (props.close) {
      handlePageUnload();
      props.setClose(false);
    }
  }, [props.close]);

  useEffect(() => {
    const width = 612;
    const height = 230;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const newWindow = window.open(
      '',
      '_blank',
      `width=${width},height=${height},left=${left},top=${top},resizable=no,fullscreen=no,location=no`,
    );

    windowRef.current = newWindow;

    // inject styles to new window
    setTimeout(() => {
      // newWindow.location.replace('');
      newWindow.document.body.style.display = 'none';
      newWindow.document.head.innerHTML = window.document.head.innerHTML;
      newWindow.document.title = props.title;
      setFavicon(newWindow);
    }, 100);

    setTimeout(() => {
      newWindow.document.body.style.display = 'block';
    }, 300);

    newWindow.addEventListener('beforeunload', handlePageUnload);

    const el = newWindow.document.createElement('div');
    el.setAttribute('id', props.id);
    setContainer(el);

    newWindow.document.body.appendChild(el);
    newWindow.document.body.setAttribute('style', 'margin: 0;'); //reset css

    return () => {
      newWindow.close();
      window.removeEventListener('beforeunload', handlePageUnload);
    };
  }, []);

  const setFavicon = (newWindow) => {
    const isPROD = window.location.origin === 'https://hp-commandcenter.com';
    const faviconUrl = isPROD
      ? 'https://docs.hpcommandcenter.com/images'
      : `${newWindow.location.origin}`;

    const link = newWindow.document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = `${faviconUrl}/favicon.ico`;
    newWindow.document.head.prepend(link);
  };

  return <>{container && createPortal(props.children, container)}</>;
};

export default PopupWindow;
