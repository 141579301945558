import styled from 'styled-components';
import React from 'react';
import { capitalizeFirstLetter, getStatus, t } from 'src/utils/commonMethods';
import { IconCheckmarkCircle, IconMinusCircle, IconWarningAlt } from '@veneer/core';

type TTableStatusRendererProps = {
  value: string;
};

const TableStatusRenderer = (props: TTableStatusRendererProps) => {
  const getStatusText = () => {
    switch (props.value) {
      case 'ERROR':
        return t('table.error');
      case 'WARNING':
        return t('table.warning');
      case 'READY':
        return t('table.ready');
    }
  };

  const getStatusIcon = () => {
    switch (props.value) {
      case 'ERROR':
        return <IconMinusCircle color="red7" filled={true} size={20} />;
      case 'WARNING':
        return <IconWarningAlt color="darkOrange6" filled={true} size={20} />;
      case 'READY':
        return <IconCheckmarkCircle color="hunterGreen7" filled={true} size={20} />;
    }
  };

  return (
    <Wrapper data-testid={'status-renderer'}>
      {getStatusIcon()}
      <StatusText>{capitalizeFirstLetter(getStatusText())}</StatusText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusText = styled.div`
  margin-left: 5px;
`;

export default TableStatusRenderer;
