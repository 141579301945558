import { permission, store } from 'src/utils/constants';

export const storePermission = (_payload, scope) => {
  switch (scope) {
    case permission.collections.CREATE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_COLLECTIONS_CREATE,
        payload: _payload,
      };
    case permission.collections.READ:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_COLLECTIONS_READ,
        payload: _payload,
      };
    case permission.collections.UPDATE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_COLLECTIONS_UPDATE,
        payload: _payload,
      };
    case permission.collections.DELETE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_COLLECTIONS_DELETE,
        payload: _payload,
      };
    case permission.contents.devices.CREATE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_CONTENTS_DEVICES_CREATE,
        payload: _payload,
      };
    case permission.contents.devices.READ:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_CONTENTS_DEVICES_READ,
        payload: _payload,
      };
    case permission.contents.devices.COUNT:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_CONTENTS_DEVICES_COUNT,
        payload: _payload,
      };
    case permission.contents.devices.UPDATE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_CONTENTS_DEVICES_UPDATE,
        payload: _payload,
      };
    case permission.contents.collections.CREATE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_CONTENTS_COLLECTIONS_CREATE,
        payload: _payload,
      };
    case permission.contents.collections.READ:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_CONTENTS_COLLECTIONS_READ,
        payload: _payload,
      };
    case permission.reports.OWNER:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_REPORTS_OWNER,
        payload: _payload,
      };
    case permission.memberships.collections.UPDATE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_MEMBERSHIPS_COLLECTIONS_UPDATE,
        payload: _payload,
      };
    case permission.participantinfos.DELETE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_PARTICIPANTINFOS_DELETE,
        payload: _payload,
      };
    case permission.devices.DELETE:
      return {
        type: store.devicesRnPm.STORE_PERMISSION_DEVICES_DELETE,
        payload: _payload,
      };
  }
};
