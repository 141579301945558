import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';

export const subscriptionsAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.subscriptions);

  const getSubscriptions = async (productNumber, serialNumber, deviceId) => {
    let response, error;

    await client
      .list({
        productNumber: productNumber,
        serialNumber: serialNumber,
        deviceId: deviceId,
      })
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const deleteSubscriptions = async (productNumber, serialNumber, deviceId, accountId) => {
    let response, error;

    await client
      .disconnect({
        productNumber: productNumber,
        serialNumber: serialNumber,
        deviceId: deviceId,
        accountId: accountId,
      })
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getSubscriptions,
    deleteSubscriptions,
  };
};
