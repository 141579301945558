import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useState, useEffect } from 'react';
import { t } from 'src/utils/commonMethods';
import { GroupTextBox } from 'src/components/molecule';
import { collectionAPI } from 'src/api/collection';
import { Button, Select } from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TEditGroupCreateProps = {
  groupData: any[];
  parentGroupList: any[];
  refreshEditGroupModalPage: () => void;
  setSelectedGroupsIdList: (e) => void;
  setSelectedGroupName: (e) => void;
  setActionCount: (e) => void;
};

const EditGroupCreate = (props: TEditGroupCreateProps) => {
  const [selectedParentGroup, setSelectedParentGroup] = useState(['All']);
  const [isFetching, setIsFetching] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [enableCreateButton, setEnableCreateButton] = useState(false);
  const [groupNameSatisfied, setGroupNameSatisfied] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState(props.parentGroupList);
  const { useToast } = useShellRootContext();
  const { createCollection } = collectionAPI();
  // TBD - String
  const i18nSelect = {
    clear: t('group.clear'),
    noResults: t('group.no_results'),
    open: t('group.open'),
    searchPlaceholder: t('group.search_placeholder'),
    selected: t('group.selected'),
    showingResult: t('group.showing_result'),
    showingResults: t('group.showing_results'),
    unselected: t('group.unselected'),
  };

  useEffect(() => {
    if (groupNameSatisfied) {
      setEnableCreateButton(true);
    } else {
      setEnableCreateButton(false);
    }
  }, [groupNameSatisfied, groupName]);

  const onSearch = (value) => {
    const filteredOptions = props.parentGroupList.filter((item) =>
      item.value.toLowerCase().includes(value.toLowerCase()),
    );
    setSelectedGroups(filteredOptions);
  };

  const handleCreate = async () => {
    const { error } = await createCollection(groupName, []);

    if (error) {
      useToast.addToast({
        id: 'createGroupFail',
        type: 'negative',
        text: t('group.create_group_fail_msg', { groupName: groupName }),
      });
    } else {
      useToast.addToast({
        id: 'createGroupSuccess',
        type: 'positive',
        text: t('group.create_group_success_msg', { groupName: groupName }),
      });
    }

    setGroupNameSatisfied(false);
  };

  const renderGroupTextBox = () => {
    const description = t('group.edit_groups_create_description');

    return (
      <CustomGroupTextBox>
        <GroupTextBox
          {...{
            groups: props.groupData,
            groupName,
            description,
            setGroupName,
            setGroupNameSatisfied,
          }}
        />
      </CustomGroupTextBox>
    );
  };

  return (
    <Wrapper>
      {renderGroupTextBox()}

      <Label data-testid="edit-group-modal-create-tab-parent-group-label">
        {t('group.parent_group')}
      </Label>

      <SelectWrapper>
        <Select
          data-testid="edit-group-modal-create-tab-select-group"
          options={selectedGroups}
          clearIcon={false}
          value={selectedParentGroup}
          i18n={i18nSelect}
          placeholder={t('group.group_all')}
          showSearch={true}
          onSearch={onSearch}
          onChange={({ value: v }) => {
            setSelectedParentGroup([v.toString()]);
          }}
        />
      </SelectWrapper>
      <SavePanel>
        <Button
          data-testid="edit-group-modal-create-tab-action-button"
          appearance="primary"
          small={true}
          onClick={() => {
            setIsFetching(true);
            props.setActionCount((prevCount: number) => {
              return prevCount + 1;
            });
            handleCreate().finally(() => {
              setIsFetching(false);
              props.refreshEditGroupModalPage();
            });
          }}
          disabled={!enableCreateButton}
          loading={isFetching}
        >
          {t('group.create')}
        </Button>
      </SavePanel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px 24px 44px 24px;
`;

const CustomGroupTextBox = styled.div`
  .vn-input {
    width: 397px;
  }
`;

const Label = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  ::after {
    content: '*';
    margin-left: 5px;
    color: ${tokens.color.red5};
    font-weight: bold;
  }
`;

const SelectWrapper = styled.div`
  width: 397px;
  min-height: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const SavePanel = styled.div`
  display: flex;
  justify-content: right;
  position: absolute;
  right: 30px;

  > button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;

export default EditGroupCreate;
