import styled from 'styled-components';
import React, { useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { Button, Modal, Select } from '@veneer/core';
import {
  TableExportAllButton,
  TableExportActionButton,
  TableAddDeviceButton,
} from 'src/components/atom';

type TTableExportListProps = {
  disabled?: boolean;
  columnConfig: any;
  columnContents: any;
};

const TableExportList = (props: TTableExportListProps) => {
  const { customRelativePath, tenantName, useToast, isWex } = useShellRootContext();
  const [showModal, setShowModal] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState([1]);

  return (
    <Wrapper data-testid="table-export-list">
      <ButtonArea>
        {isWex && <TableAddDeviceButton />}
        <TableExportAllButton disabled={props.disabled} setShowModal={setShowModal} />
      </ButtonArea>

      <Modal
        id="form-modal"
        data-testid="export-modal"
        className="body-large"
        closeOnBlur={false}
        maxWidth="612px"
        alignFooter="end"
        show={showModal}
        title={t('table.export_devices')}
        footer={
          <Footer>
            <TableExportActionButton
              setShowModal={setShowModal}
              columnConfig={props.columnConfig}
              columnContents={props.columnContents}
              customRelativePath={customRelativePath}
              tenantName={tenantName}
              useToast={useToast}
            />
            <Button
              data-testid="cancel-button"
              appearance="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              {t('table.cancel')}
            </Button>
          </Footer>
        }
      >
        <Description data-testid="description">{t('table.export_description')}</Description>

        <SelectorWrapper data-testid="selector-wrapper">
          <Select
            id="export-type"
            data-testid="export-type"
            label={t('table.select_file_type')}
            options={[{ value: 1, label: 'CSV' }]}
            clearIcon={false}
            value={selectedExportType}
            onChange={({ value: v }) => setSelectedExportType([v as number])}
          />
        </SelectorWrapper>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ButtonArea = styled.div`
  display: flex;
`;

const Footer = styled.div.attrs(() => {
  return { className: 'vn-button-group--responsive' };
})`
  display: flex;
  width: 100%;
  height: 38px;
  button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;
const Description = styled.div`
  font-size: 18px;
  white-space: pre-wrap;
`;

const SelectorWrapper = styled.div`
  width: 200px;
  margin-top: 20px;
`;

export default TableExportList;
