import { store } from 'src/utils/constants';

const initialState = {
  collectionsCreateRnPm: true,
  collectionsReadRnPm: true,
  collectionsUpdateRnPm: true,
  collectionsDeleteRnPm: true,
  contentsDevicesCreateRnPm: true,
  contentsDevicesReadRnPm: true,
  contentsDevicesCountRnPm: true,
  contentsDevicesUpdateRnPm: true,
  contentsCollectionsCreateRnPm: true,
  contentsCollectionsReadRnPm: true,
  reportsOwnerRnPm: true,
  membershipsCollectionsUpdateRnPm: true,
  participantinfosDeleteRnPm: true,
  devicesDeleteRnPm: true,
};

const devicesRnPmReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.devicesRnPm.STORE_PERMISSION_COLLECTIONS_CREATE:
      return {
        ...state,
        collectionsCreateRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_COLLECTIONS_READ:
      return {
        ...state,
        collectionsReadRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_COLLECTIONS_UPDATE:
      return {
        ...state,
        collectionsUpdateRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_COLLECTIONS_DELETE:
      return {
        ...state,
        collectionsDeleteRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_CONTENTS_DEVICES_CREATE:
      return {
        ...state,
        contentsDevicesCreateRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_CONTENTS_DEVICES_READ:
      return {
        ...state,
        contentsDevicesReadRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_CONTENTS_DEVICES_COUNT:
      return {
        ...state,
        contentsDevicesCountRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_CONTENTS_DEVICES_UPDATE:
      return {
        ...state,
        contentsDevicesUpdateRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_CONTENTS_COLLECTIONS_CREATE:
      return {
        ...state,
        contentsCollectionsCreateRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_CONTENTS_COLLECTIONS_READ:
      return {
        ...state,
        contentsCollectionsReadRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_REPORTS_OWNER:
      return {
        ...state,
        reportsOwnerRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_MEMBERSHIPS_COLLECTIONS_UPDATE:
      return {
        ...state,
        membershipsCollectionsUpdateRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_PARTICIPANTINFOS_DELETE:
      return {
        ...state,
        participantinfosDeleteRnPm: action.payload,
      };
    case store.devicesRnPm.STORE_PERMISSION_DEVICES_DELETE:
      return {
        ...state,
        devicesDeleteRnPm: action.payload,
      };
    case store.devicesRnPm.RESET_DEVICES_RNPM_REDUCER:
      return {
        collectionsCreateRnPm: true,
        collectionsReadRnPm: true,
        collectionsUpdateRnPm: true,
        collectionsDeleteRnPm: true,
        contentsDevicesCreateRnPm: true,
        contentsDevicesReadRnPm: true,
        contentsDevicesCountRnPm: true,
        contentsDevicesUpdateRnPm: true,
        contentsCollectionsCreateRnPm: true,
        contentsCollectionsReadRnPm: true,
        reportsOwnerRnPm: true,
        membershipsCollectionsUpdateRnPm: true,
        participantinfosDeleteRnPm: true,
        devicesDeleteRnPm: true,
      };
    default:
      return state;
  }
};

export default devicesRnPmReducer;
