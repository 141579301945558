import styled from 'styled-components';
import tokens from '@veneer/tokens';

const ModalFooter = styled.div.attrs(() => {
  return { className: 'vn-button-group--responsive' };
})`
  display: flex;
  height: 38px;
  width: 100%;

  button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;

const ModalTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px !important;
  min-height: 48px;
  width: 448px;
`;

const ModalTitle = styled.label`
  font-family: var(--fontFamilyBase);
  font-size: 28px;
  line-height: 24px;
  vertical-align: middle !important;
  margin-left: 8px;
`;

const ModalContent = styled.div`
  font-family: var(--fontFamilyRegular);
  font-size: ${tokens.typography.size3};
  line-height: ${tokens.typography.lineHeight1};
  padding-bottom: 8px;
`;

export { ModalFooter, ModalTitleWrapper, ModalTitle, ModalContent };
