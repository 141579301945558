import { Button } from '@veneer/core';
import React from 'react';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { t } from 'src/utils/commonMethods';
import styled from 'styled-components';

const TableAddDeviceButton = () => {
  const { setShowAddDeviceModal, setAddDeviceModalTitle, setAddDeviceFooterButtonName } =
    useShellRootContext();
  return (
    <ButtonWrapper>
      <Button
        data-testid={'table-add-device-add-button'}
        onClick={() => {
          setAddDeviceFooterButtonName(t('table.add_device_modal.cancel'));
          setAddDeviceModalTitle(t('table.add_device_modal.add_device'));
          setShowAddDeviceModal(true);
        }}
        appearance={'secondary'}
      >
        {t('table.add_device_modal.add')}
      </Button>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  margin-right: 12px;
`;

export default TableAddDeviceButton;
