import styled from 'styled-components';
import React, { useMemo } from 'react';
import { TreeView } from '@veneer/core';
import { useDispatch } from 'react-redux';
import { getLokalisedGroupLabel } from 'src/utils/groupMethods';
import {
  storeMoveGroupModalSelectedId,
  storeMoveGroupModalSelectedName,
} from 'src/store/devicesGroup/action';

type TMoveGroupTreeProps = {
  groups: any;
  useToast: any;
  ungroupedGroupUuid: string;
  allDevicesGroupUuid: string;
  contentsDevicesCountRnPm: boolean;
  contentsCollectionsReadRnPm: boolean;
};

const MoveGroupTree = (props: TMoveGroupTreeProps) => {
  const dispatch = useDispatch();

  const enableCondition =
    props.contentsDevicesCountRnPm &&
    props.contentsCollectionsReadRnPm &&
    props.groups.length !== 0;

  const createTreeViewNodes = (groupData) => {
    let parentNodes = [];
    const group_Ungrouped = groupData
      .filter((group) => group.id == props.ungroupedGroupUuid)
      .map((group) => ({
        id: group.id,
        label: getLokalisedGroupLabel(group.name),
        totalChildren: group.devices,
      }));

    const childNodes = groupData
      .filter(
        (group) => group.id !== props.allDevicesGroupUuid && group.id !== props.ungroupedGroupUuid,
      )
      .map((group) => ({
        id: group.id,
        label: getLokalisedGroupLabel(group.name),
        totalChildren: group.devices,
      }));

    if (group_Ungrouped.length > 0) {
      childNodes.unshift(group_Ungrouped[0]);
    }

    parentNodes = groupData
      .filter((group) => group.id == props.allDevicesGroupUuid)
      .map((group) => {
        return {
          id: group.id,
          label: getLokalisedGroupLabel(group.name),
          totalChildren: group.devices,
          nodes: childNodes,
        };
      });

    return parentNodes;
  };

  const handleMoveGroupModalData = (id) => {
    dispatch(storeMoveGroupModalSelectedId([id]));
    dispatch(
      storeMoveGroupModalSelectedName(
        props.groups.find((group) => {
          return group.id == id;
        }).name,
      ),
    );
  };

  const renderMoveGroupTree = () => {
    const nodes = createTreeViewNodes(props.groups);

    return (
      <StyledTreeView
        id="moveto-group-tree"
        data-testid="moveto-group-tree"
        nodes={nodes}
        onChange={(e, id) => {
          handleMoveGroupModalData(id);
        }}
        // params for custom styling
        defaultExpandedNodes={[props.allDevicesGroupUuid]}
        allDevicesGroupUuid={props.allDevicesGroupUuid}
        ungroupedGroupUuid={props.ungroupedGroupUuid}
      />
    );
  };

  const memoRenderComponent = useMemo(() => {
    if (enableCondition) {
      console.log('[devices] render <MoveGroupTree />');

      return <>{renderMoveGroupTree()}</>;
    } else {
      return <></>;
    }
  }, [enableCondition]);

  return <Wrapper data-testid="move-group-tree-wrapper">{memoRenderComponent}</Wrapper>;
};

const Wrapper = styled.div`
  width: 532px;
  max-height: 320px;
  margin-right: 24px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 2px solid rgba(33, 33, 33, 0.1);

  li {
  }
`;

const StyledTreeView = styled(TreeView)`
  overflow: auto;
  max-height: 320px;

  li div span {
    line-height: 20px;
  }

  li[id='${(props) => props.allDevicesGroupUuid}'],
  li[id='${(props) => props.ungroupedGroupUuid}'] {
    > div:first-child {
      pointer-events: none;
      background: none !important;

      .expand-icon {
        pointer-events: all;
      }
    }

    > div:first-child > span {
      color: #dcdcdc;
    }
  }

  li[id='${(props) => props.allDevicesGroupUuid}'] > li > div {
    margin: 0;
    white-space: nowrap;
  }
`;

export default MoveGroupTree;
