import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { useDispatch } from 'react-redux';
import { useStoreState } from 'src/store/useStoreState';
import { useResponsive } from 'src/hooks/useResponsive';
import { GroupErrorMsg } from 'src/components/atom';
import { InfiniteScrollTree } from 'src/components/molecule';
import { MainGroupButton } from 'src/components/organism';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { getTreeViewParentNodes } from 'src/utils/groupMethods';
import { Button, IconChevronRight, Scrollbar, SideBar, TreeView } from '@veneer/core';
import {
  storeMainGroupSelectedId,
  storeMainGroupSelectedName,
} from 'src/store/devicesGroup/action';
import {
  storeCurrentPage,
  storeSelectedItems,
  storeCfgApiRequestOptions,
  storeDeviceTableRefresh,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';

type TMainGroupProps = {
  mainGroupData: any[];
  error: boolean;
  isFetchingForTable: boolean;
  cfgApiRequestOptions: any;
  setShowEditGroupModal: (boolean) => void;
};

const MainGroup = (props: TMainGroupProps) => {
  const { showDevicesGroupCUD, isWex } = useShellRootContext();
  const { devicesState, devicesGroupState } = useStoreState();
  const { allDevicesGroupUuid, ungroupedGroupUuid, mainGroupSelectedId, mainGroupSelectedName } =
    devicesGroupState;
  const { cfgApiRequestOptions, tableData } = devicesState;
  const [groupData, setGroupData] = useState(props.mainGroupData);
  const [showSideBar, setShowSideBar] = useState(false);
  const { isMobile, isTablet } = useResponsive();

  const nodes = getTreeViewParentNodes(groupData, allDevicesGroupUuid, ungroupedGroupUuid);

  const dispatch = useDispatch();

  useEffect(() => {
    setGroupData(props.mainGroupData);
  }, [props.mainGroupData]);

  const updateSelectedGroupId = (groupId) => {
    dispatch(storeMainGroupSelectedId(groupId));

    const filteredGroup = groupData.filter((group) => {
      return group.id === groupId;
    });
    dispatch(storeMainGroupSelectedName(filteredGroup[0].name));
    dispatch(storeSelectedItems([]));
    dispatch(storeCurrentPage(1));
    dispatch(storeCfgApiRequestOptions({ ...cfgApiRequestOptions, offset: 0 }));
    dispatch(storeTotalDeviceCountInGroup(filteredGroup[0].devices));

    dispatch(storeDeviceTableRefresh(true));
  };

  const handleChangeEvent = (event, id) => {
    updateSelectedGroupId(id);
    event.preventDefault();
  };

  const getHeightState = () => {
    if (props.isFetchingForTable) {
      return 'fetching';
    }
    return tableData.length === 0
      ? 'no-item'
      : Math.min(cfgApiRequestOptions.limit, tableData.length);
  };

  const renderTreeView = (displayedNodes) => {
    return (
      <TreeView
        data-testid={'main-group-tree-view'}
        defaultExpandedNodes={[allDevicesGroupUuid]}
        defaultSelectedNodes={[mainGroupSelectedId]}
        nodes={displayedNodes}
        onChange={handleChangeEvent}
      />
    );
  };

  const getResponsiveGroupSelector = () => {
    return (
      <ResponsiveTitle>
        <SelectGroupLabel>{`${t('group.selected')} ${t(
          'table.group',
        ).toLowerCase()}`}</SelectGroupLabel>
        <Button
          data-testid={'main-group-sidebar-button'}
          appearance={'ghost'}
          trailingIcon={<IconChevronRight id={'select-button'} />}
          small
          onClick={() => setShowSideBar(true)}
        >
          {mainGroupSelectedName}
        </Button>
        <SideBar
          data-testid={'main-group-sidebar'}
          content={
            <InfiniteScrollTree nodes={nodes} render={renderTreeView} id={'main-group-tree'} />
          }
          show={showSideBar}
          position="start"
          onClose={() => setShowSideBar(false)}
          onCollapse={() => setShowSideBar(false)}
          onExpand={() => setShowSideBar(true)}
        />
      </ResponsiveTitle>
    );
  };

  return (
    <Wrapper isWex={isWex} data-testid={'main-group-wrapper'}>
      <UpperArea data-testid={'main-group-wrapper-upper-area'}>
        <>
          <Title>{t('group.groups')}</Title>
          {isMobile && getResponsiveGroupSelector()}
        </>

        {showDevicesGroupCUD && (
          <MainGroupButton
            disabled={props.error}
            setShowEditGroupModal={props.setShowEditGroupModal}
          />
        )}
      </UpperArea>

      {props.error ? (
        <GroupErrorMsg />
      ) : (
        <Scrollbar
          customStyle={{
            overflow: 'auto',
            width: '256px',
          }}
        >
          <TreeViewWrapper
            data-testid={'main-group-treeview-wrapper'}
            id={'main-group-tree__wrapper'}
            disabled={props.isFetchingForTable}
            responsive={isMobile || isTablet}
            heightState={getHeightState()}
          >
            <InfiniteScrollTree nodes={nodes} render={renderTreeView} id={'main-group-tree'} />
          </TreeViewWrapper>
        </Scrollbar>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${(props) => props.isWex && 'padding:24px;'}
`;

const Title = styled.div`
  font-size: 20px;
  padding: 6px 0;
  font-weight: 400;
  flex-basis: 170px;
  flex-shrink: 0;
`;

const ResponsiveTitle = styled.div`
  display: flex;
  align-items: center;
`;

const SelectGroupLabel = styled.span`
  font-weight: bold;
`;

const UpperArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 576px) {
    ${SelectGroupLabel} {
      display: none;
    }

    button {
      justify-content: left;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    ${Title} {
      display: none;
    }
  }

  @media (min-width: 768px) {
    ${ResponsiveTitle} {
      display: none;
    }
  }
`;
const TreeViewWrapper = styled.div`
  margin-top: 12px;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }

  ${(props) => {
    if (props.disabled) {
      return css`
        pointer-events: none;
        opacity: 0.5;
      `;
    }
  }}

  max-height: ${(props) => {
    switch (props.heightState) {
      case 'fetching':
      case 'no-item':
        return '392px';
      default:
        if (props.heightState <= 8) return '392px';
        return props.heightState * 48 + 'px';
    }
  }};

  min-height: ${(props) => {
    if (
      props.heightState == 'fetching' ||
      props.heightState == 'no-item' ||
      props.heightState <= 8
    ) {
      return '392px';
    }
  }};

  li {
    li > div:first-child {
      white-space: nowrap;
    }
  }
`;

export default MainGroup;
