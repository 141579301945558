import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { Button, IconWarningAlt, Modal } from '@veneer/core';
import { programMgtAPI } from 'src/api/programMgt';
import { accountMgtAPI } from 'src/api/accountMgt';
import { useStoreState } from 'src/store/useStoreState';
import { useRefreshHook } from 'src/hooks/useRefreshHook';
import { subscriptionsAPI } from 'src/api/subscriptions';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { ModalTitle, ModalFooter, ModalContent, ModalTitleWrapper } from 'src/styles/global';

type TUnregisterModalProps = {
  setIsLoadingMfe: (isLoading: boolean) => void;
  showModal: boolean;
  storeShowModal: (e: boolean) => void;
};

const UnregisterModal = (props: TUnregisterModalProps) => {
  const { useToast } = useShellRootContext();
  const { refreshPage } = useRefreshHook();
  const { getDeviceParticipantInfo, deleteDeviceParticipantInfo } = programMgtAPI();
  const { deleteDevice } = accountMgtAPI();
  const { getSubscriptions, deleteSubscriptions } = subscriptionsAPI();
  const [isFetching, setIsFetching] = useState(false);
  const { devicesState } = useStoreState();
  const { selectedItems } = devicesState;

  const selectedTableData = selectedItems;
  const devicesCount = selectedTableData.length;

  useEffect(() => {
    props.setIsLoadingMfe(false);
  }, []);

  const displayErrorToast = () => {
    useToast.addToast({
      id: 'unregisterModalFail',
      type: 'negative',
      text: t('table.unregister_fail_msg', {
        devicesCount: devicesCount,
      }),
    });
  };
  const displaySuccessToast = () => {
    useToast.addToast({
      id: 'unregisterModalSuccess',
      type: 'informative',
      text: t('table.unregister_success_msg', {
        devicesCount: devicesCount,
      }),
    });
  };

  const handleUnregisterModal = async () => {
    for (const device of selectedTableData) {
      //remove partner link id
      if (device.solutions.includes('ws-hp.com/partnerlinksvc')) {
        const { response: getSubscriptionRes } = await getSubscriptions(
          device.identity.makeAndModel.number,
          device.identity.serialNumber,
          device.deviceId,
        );

        if (getSubscriptionRes.data.length > 0) {
          const { error } = await deleteSubscriptions(
            device.productNumber,
            device.serialNumber.props.value,
            device.deviceId,
            getSubscriptionRes.data[0],
          );
          if (error) {
            displayErrorToast();
            return;
          }
        }
      }

      //offboard device
      const { response: getParticipantInfoRes, error: getParticipantInfoError } =
        await getDeviceParticipantInfo(device.productNumber, device.serialNumber.props.value);

      if (getParticipantInfoRes != undefined) {
        //pre-registered device
        const { error } = await deleteDeviceParticipantInfo(
          device.productNumber,
          device.serialNumber.props.value,
        );
        if (error) {
          displayErrorToast();
          return;
        }
      } else if (
        getParticipantInfoRes == undefined &&
        getParticipantInfoError != undefined &&
        (getParticipantInfoError.response.status == 401 ||
          getParticipantInfoError.response.status == 404)
      ) {
        //none pre-registered device
        const { error } = await deleteDevice(device.accountId, device.deviceId);
        if (error) {
          displayErrorToast();
          return;
        }
      } else {
        displayErrorToast();
        return;
      }
    }
    displaySuccessToast();
    refreshPage();
  };

  return (
    <Modal
      data-testid="unregister-modal"
      show={props.showModal}
      title=""
      maxWidth="612px"
      alignFooter="end"
      footer={
        <ModalFooter>
          <Button
            data-testid="unregister-modal-unregister-button"
            appearance="danger"
            onClick={() => {
              setIsFetching(true);
              handleUnregisterModal().finally(() => {
                setIsFetching(false);
                props.storeShowModal(false);
              });
            }}
            loading={isFetching}
          >
            {t('table.unregister')}
          </Button>

          <Button
            data-testid="unregister-modal-cancel-button"
            appearance="secondary"
            onClick={() => props.storeShowModal(false)}
            disabled={isFetching}
          >
            {t('table.cancel')}
          </Button>
        </ModalFooter>
      }
    >
      <ModalTitleWrapper>
        <IconWarningAlt filled color="darkOrange6" size={36} />
        <ModalTitle data-testid="unregister-modal-title">{t('table.unregister')}</ModalTitle>
      </ModalTitleWrapper>
      <ModalContent>{t('table.unregister_description')}</ModalContent>

      <SubContent>{t('table.unregister_sub_description')}</SubContent>
    </Modal>
  );
};

const SubContent = styled.div`
  padding-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

export default UnregisterModal;
