import styled from 'styled-components';
import React from 'react';
import { ProgressIndicator } from '@veneer/core';

const CommonLoading = () => {
  return (
    <Wrapper data-testid="hp-loading-section">
      <ProgressIndicator />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  height: 10rem;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CommonLoading;
