import { useEffect, useState } from 'react';

export const useWaitAnimation = (flag: boolean, waitTime = 500) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (flag) {
      setRender(true);
    } else {
      setTimeout(() => setRender(false), waitTime);
    }
  }, [flag]);

  return render;
};

const _useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    setMatches(window.matchMedia(query).matches);
  }, []);

  useEffect(() => {
    const matchQueryList = window.matchMedia(query);
    const handleChange = (e) => setMatches(e.matches);
    matchQueryList.addEventListener('change', handleChange);

    return () => {
      matchQueryList.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
};

export const useResponsive: any = () => {
  const md = 768;
  const xs = 375;

  const isPC = _useMediaQuery(`(min-width: ${md}px)`);
  const isTablet = _useMediaQuery(`(min-width: ${xs}px) and (max-width: ${md - 1}px)`);
  const isMobile = _useMediaQuery(`(max-width: ${xs - 1}px)`);

  return { isPC, isTablet, isMobile };
};
