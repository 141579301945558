import moment from 'moment/moment';

export const downloadJsonMethods = (devices) => {
  if (!Array.isArray(devices)) {
    return;
  }
  const appName = devices[0]?.appTitle ?? '-';
  const exportObj = {};

  devices.forEach((device) => {
    exportObj[device.deviceId] = device.configData;
  });

  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
  const downloadAnchorNode = document.createElement('a');

  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `AppConfiguration_${appName}_${_getDate()}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const _getDate = () => {
  return moment(new Date()).format('MMDDYYYY_HHmm');
};
