import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useEffect, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { Modal, Button } from '@veneer/core';
import { collectionAPI } from 'src/api/collection';
import { useStoreState } from 'src/store/useStoreState';
import { useRefreshHook } from 'src/hooks/useRefreshHook';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TRemoveFromGroupModalProps = {
  showModal: boolean;
  storeShowModal: (e: boolean) => void;
  setIsLoadingMfe: (e: boolean) => void;
};

const RemoveFromGroupModal = (props: TRemoveFromGroupModalProps) => {
  const { refreshPage } = useRefreshHook();
  const { useToast, isWex } = useShellRootContext();
  const { patchTypedCollectionById } = collectionAPI();
  const { devicesGroupState, devicesState } = useStoreState();
  const { mainGroupSelectedId, ungroupedGroupUuid } = devicesGroupState;
  const { selectedItems } = devicesState;
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    props.setIsLoadingMfe(false);
  }, []);

  const RemoveDevicesFromGroup = async () => {
    //Get device id from selected device list
    const selectedDeviceIds = selectedItems.map((data) => {
      return {
        deviceId: data.deviceId,
        groupId: data.groups[0] == undefined ? ungroupedGroupUuid : data.groups[0].uuid,
      };
    });

    //remove device from prev group when selected group is specific group
    //Get prev group's device list

    const { response, error } = await patchTypedCollectionById(
      mainGroupSelectedId,
      'devices',
      [],
      selectedDeviceIds.map((selectedDevice) => {
        return selectedDevice.deviceId;
      }),
    );

    if (response) {
      useToast.addToast({
        id: 'success',
        type: 'positive',
        text: t('table.remove_success_msg'),
      });
    }

    if (error) {
      useToast.addToast({
        id: 'retry',
        type: 'negative',
        text: t('table.remove_fail_msg'),
      });
    }

    refreshPage();
  };

  return (
    <Modal
      data-testid="remove-from-group-modal"
      id="remove-from-group-modal"
      show={props.showModal}
      title=""
      maxWidth="612px"
      alignFooter={isWex ? undefined : 'end'}
      footer={
        <Footer isWex={isWex}>
          <Button
            data-testid="remove-from-group-modal-action-button"
            appearance="primary"
            onClick={() => {
              setIsFetching(true);
              RemoveDevicesFromGroup().finally(() => {
                setIsFetching(false);
                props.storeShowModal(false);
              });
            }}
            loading={isFetching}
          >
            {t('table.remove')}
          </Button>

          <Button
            appearance="secondary"
            onClick={() => props.storeShowModal(false)}
            data-testid="remove-from-group-modal-cancel-button"
            disabled={isFetching}
          >
            {t('table.cancel')}
          </Button>
        </Footer>
      }
    >
      <Title data-testid="remove-from-group-modal-title">{t('table.remove_from_group')}</Title>

      <Content data-testid="remove-from-group-modal-desc">{t('table.remove_description')}</Content>
    </Modal>
  );
};

const Footer = styled.div.attrs(() => {
  return { className: 'vn-button-group--responsive' };
})`
  display: flex;
  width: 100%;
  ${(props) =>
    !props.isWex &&
    `
    height: 38px;    
  `}
  button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;

const Title = styled.div`
  padding-bottom: 20px !important;
  width: 448px;

  font-family: var(--fontRamilyRegular);
  font-size: ${tokens.typography.size7};
  line-height: ${tokens.typography.lineHeight6};
  vertical-align: middle !important;
`;

const Content = styled.div`
  font-family: var(--fontRamilyRegular);
  font-size: ${tokens.typography.size3};
  line-height: ${tokens.typography.lineHeight3};
`;

export default RemoveFromGroupModal;
