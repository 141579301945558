import React, { useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { getGroups } from 'src/utils/groupMethods';
import { collectionAPI } from 'src/api/collection';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { EditGroupModalTemplate } from 'src/components/template';
import { CommonLoading } from 'src/components/atom';
import { Modal } from '@veneer/core';
import styled from 'styled-components';

type TEditGroupModalPageProps = {
  showModal: boolean;
  setShowModal: (e) => void;
};

const EditGroupModalPage = (props: TEditGroupModalPageProps) => {
  //public state
  const { devicesGroupState } = useStoreState();
  const { allDevicesGroupUuid, ungroupedGroupUuid } = devicesGroupState;
  const [groupData, setGroupData] = useState([]);
  const [doRefresh, setDoRefresh] = useState(false);
  const [selectedGroupsIdList, setSelectedGroupsIdList] = useState([]);
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [parentGroupList, setParentGroupList] = useState([]);
  const [actionCount, setActionCount] = useState(0);
  const portalRef = useRef<HTMLDivElement>(null);
  //private state
  const { useToast } = useShellRootContext();
  const [isFetchingGetGroup, setIsFetchingGetGroup] = useState(true);
  const [errorGetGroup, setErrorGetGroup] = useState(null);
  const [enableRender, setEnableRender] = useState(false);

  //etc
  const { getCollections } = collectionAPI();

  /* useEffect */
  useEffect(() => {
    apiFetchGroupTreeData();
  }, [doRefresh]);

  useEffect(() => {
    let completeGetGroup = false;
    //check Get predifine

    //check GET group API result
    if (isFetchingGetGroup == false && errorGetGroup == null) completeGetGroup = true;
    else completeGetGroup = false;

    setEnableRender(completeGetGroup);
  }, [isFetchingGetGroup]);

  useEffect(() => {
    if (errorGetGroup) {
      useToast.addToast({
        id: 'retry',
        type: 'negative',
        text: t('unable_to_load_data'),
      });
    }
  }, [errorGetGroup]);

  /* private function */
  const apiFetchGroupTreeData = () => {
    console.log('[EditGroupModalPage] apiFetchGroupTreeData');
    setIsFetchingGetGroup(true);

    getGroups(
      getCollections,
      (data) => {
        setGroupData(data);
        setErrorGetGroup(null);
      },
      setErrorGetGroup,
    ).finally(() => {
      setParentGroupList([{ label: t('group.group_all'), value: 'All' }]);
      setIsFetchingGetGroup(false);
    });
  };

  /* public function */
  const refreshEditGroupModalPage = () => {
    setSelectedGroupsIdList([]);
    setSelectedGroupName('');
    setDoRefresh(!doRefresh);
  };

  /* render */
  const memoEditGroupModalPage = useMemo(() => {
    if (enableRender) {
      return (
        <EditGroupModalTemplate
          enableRender={enableRender}
          showEditGroupModal={props.showModal}
          groupData={groupData}
          allDevicesGroupUuid={allDevicesGroupUuid}
          ungroupedGroupUuid={ungroupedGroupUuid}
          selectedGroupsIdList={selectedGroupsIdList}
          selectedGroupName={selectedGroupName}
          parentGroupList={parentGroupList}
          actionCount={actionCount}
          setShowEditGroupModal={props.setShowModal}
          setSelectedGroupsIdList={setSelectedGroupsIdList}
          setSelectedGroupName={setSelectedGroupName}
          setActionCount={setActionCount}
          refreshEditGroupModalPage={refreshEditGroupModalPage}
          data-testid="edit-group-modal-template"
        />
      );
    } else {
      return <></>;
    }
  }, [enableRender, selectedGroupsIdList]);

  return (
    <>
      <PortalContainer ref={portalRef} data-testid={'edit-group-modal-page'} />
      <Modal
        maxWidth="1400px"
        show={props.showModal}
        data-testid="edit-group-modal"
        portal={true}
        portalContainer={portalRef.current}
      >
        {isFetchingGetGroup ? <CommonLoading /> : <>{memoEditGroupModalPage}</>}
      </Modal>
    </>
  );
};

const PortalContainer = styled.div`
  > div > div > div {
    padding: unset !important;
  }
`;
export default EditGroupModalPage;
