import { useApiClient } from 'src/api/useApiClient';
import { isMockUpMode } from 'src/utils/commonMethods';
import { service, sessionStorageKey } from 'src/utils/constants';
import {
  refinedGetDeviceGroups,
  refinedGetDeviceGroupsAll,
  refinedGetDeviceGroupsUngrouped,
  refinedUseGetTypedCollectionById,
} from 'src/api/collection/collectionRefinery';

import getAllCollectionsMock from 'src/mock/collection/get-all-collections.json';
import getUngroupedCollectionsMock from 'src/mock/collection/get-ungrouped-collections.json';
import getCollectionsMock from 'src/mock/collection/get-collections.json';
import getTypedCollectionByIdMock from 'src/mock/collection/get-typed-collection-by-id.json';

export const collectionAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.collection);

  const _getServiceID = () => {
    if (sessionStorage.getItem(sessionStorageKey.serviceID) != null)
      return sessionStorage.getItem(sessionStorageKey.serviceID);
    else return '';
  };

  const getCollections = async (offset, limit, name?) => {
    let response, error, refinedData;
    const serviceID = _getServiceID();

    if (isMockUpMode()) {
      // await new Promise((resolve) => setTimeout(resolve, 500));

      // let fileName;
      //
      // switch (name) {
      //   case 'All':
      //     fileName = 'get-all-collections';
      //     break;
      //   case 'Ungrouped':
      //     fileName = 'get-ungrouped-collections';
      //     break;
      //   default:
      //     fileName = 'get-collections';
      // }

      switch (name) {
        case 'All':
          refinedData = refinedGetDeviceGroupsAll(getAllCollectionsMock);
          break;
        case 'Ungrouped':
          refinedData = refinedGetDeviceGroupsUngrouped(getUngroupedCollectionsMock);
          break;
        default:
          refinedData = refinedGetDeviceGroups(getCollectionsMock);
      }
      response = refinedData;

      // await getMockUpData(`collection/${fileName}`).then((data) => {
      //   switch (name) {
      //     case 'All':
      //       refinedData = refinedGetDeviceGroupsAll(data);
      //       break;
      //     case 'Ungrouped':
      //       refinedData = refinedGetDeviceGroupsUngrouped(data);
      //       break;
      //     default:
      //       refinedData = refinedGetDeviceGroups(data);
      //   }
      //   response = refinedData;
      // });

      return { response, error };
    }

    await client
      .getCollections(
        offset,
        limit,
        undefined,
        name ?? undefined,
        undefined,
        undefined,
        undefined,
        true,
        serviceID !== '' ? `serviceId: ${serviceID}` : undefined,
      )
      .then((res) => {
        switch (name) {
          case 'All':
            refinedData = refinedGetDeviceGroupsAll(res.data);
            break;
          case 'Ungrouped':
            refinedData = refinedGetDeviceGroupsUngrouped(res.data);
            break;
          default:
            refinedData = refinedGetDeviceGroups(res.data);
        }
        response = refinedData;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const createCollection = async (collectionName, deviceIdList) => {
    let response, error;

    if (isMockUpMode()) {
      return { response, error };
    }

    await client
      .createCollection({
        data: {
          name: collectionName,
          visibility: 'visible',
          events: {
            devices: ['all'],
            users: ['all'],
            collections: ['all'],
          },
          devices: deviceIdList,
          users: null,
          collections: null,
        },
      })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const patchCollectionById = async (collectionId, collectionName) => {
    let response, error;

    await client
      .patchCollectionById(collectionId, {
        data: {
          name: collectionName,
        },
      })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const deleteCollectionByIds = async (collectionIds) => {
    let response, error;

    for (let i = 0; i < collectionIds.length; i++) {
      await client
        .deleteCollectionById(collectionIds[i])
        .then((res) => {
          response = res.data;
        })
        .catch((err) => {
          error = err;
        });
    }

    return { response, error };
  };

  const getTypedCollectionById = async (collectionId, options) => {
    let response, error;
    const serviceID = _getServiceID();
    if (isMockUpMode()) {
      response = refinedUseGetTypedCollectionById(getTypedCollectionByIdMock);

      // await getMockUpData('collection/get-typed-collection-by-id').then((data) => {
      //   response = refinedUseGetTypedCollectionById(data);
      // });
      return { response, error };
    }

    await client
      .getTypedCollectionById(
        collectionId,
        'devices',
        options.offset,
        options.limit,
        options.sortBy,
        serviceID !== '' ? `serviceId:${serviceID}` : undefined,
        options.recursiveExpansion,
      )
      .then((res) => {
        response = refinedUseGetTypedCollectionById(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const patchTypedCollectionById = async (collectionId, entityType, addList, removeList) => {
    if (addList == null) addList = [];
    if (removeList == null) removeList = [];

    let response, error;
    if (isMockUpMode()) {
      return { response: { data: '200 ok' }, error: undefined };
    }

    await client
      .patchTypedCollectionById(collectionId, entityType, {
        data: {
          add: addList,
          remove: removeList,
        },
      })
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getCollections,
    createCollection,
    patchCollectionById,
    deleteCollectionByIds,
    getTypedCollectionById,
    patchTypedCollectionById,
  };
};
