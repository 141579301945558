import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';

export const programMgtAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.programMgt);

  const getDeviceParticipantInfo = async (productNumber, serialNumber) => {
    let response, error;

    await client
      .getDeviceParticipantInfo(productNumber, serialNumber)
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const deleteDeviceParticipantInfo = async (productNumber, serialNumber) => {
    let response, error;

    await client
      .deleteDeviceParticipantInfo({
        productNumber: productNumber,
        serialNumber: serialNumber,
      })
      .then((res) => {
        response = res;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getDeviceParticipantInfo,
    deleteDeviceParticipantInfo,
  };
};
