import React, { useState, useEffect, useMemo } from 'react';
import useTableColumn from 'src/hooks/useTableColumn';
import { useSearch } from 'src/hooks/useSearch';
import { getGroups } from 'src/utils/groupMethods';
import { useDispatch } from 'react-redux';
import { collectionAPI } from 'src/api/collection';
import { CommonLoading } from 'src/components/atom';
import { getTableId, setTableColumns } from 'src/utils/commonMethods';
import { useStoreState } from 'src/store/useStoreState';
import { fullListOption } from 'src/utils/constants';
import {
  storeDeviceTableRefresh,
  storeTableData,
  storeTotalDeviceCountInGroup,
} from 'src/store/devices/action';
import { useTableHandler } from 'src/hooks/useTableHandler';
import { DeviceMainTemplate } from 'src/components/template';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import {
  storeMainGroupData,
  storeUngroupedGroupUuid,
  storeAllDevicesGroupUuid,
  storeMainGroupSelectedId,
  storeMainGroupRefresh,
} from 'src/store/devicesGroup/action';

type TDeviceMainPageProps = {
  setShowEditGroupModal: (boolean) => void;
};

const DeviceMainPage = (props: TDeviceMainPageProps) => {
  const { customRelativePath, isWex } = useShellRootContext();
  const { devicesState, devicesGroupState, devicesRnPmState } = useStoreState();

  const { contentsDevicesCountRnPm, contentsCollectionsReadRnPm } = devicesRnPmState;
  const { allDevicesGroupUuid, ungroupedGroupUuid, mainGroupSelectedId, mainGroupRefresh } =
    devicesGroupState;
  const { deviceTableRefresh, searchItem, cfgApiRequestOptions } = devicesState;

  // api related methods
  const { getCollections, getTypedCollectionById } = collectionAPI();
  const dispatch = useDispatch();

  // table related variables * DO NOT CREATE DEVICE COUNT IN REDUX *
  const tableId = getTableId(customRelativePath);

  const { columnConfig } = useTableColumn();
  const { triggerSearch } = useSearch(tableId, columnConfig);
  const { tableHandler } = useTableHandler();
  const enableRnPm = contentsDevicesCountRnPm && contentsCollectionsReadRnPm;

  // fetch related flags
  //TODO: Need to inject as props all the way down to Devices Table
  // setApiRequestOption: change of search key value, pageSize, page number, sort, filter
  // tableRefresh: Anywhere that needs refresh
  const [isFetchingAllGroup, setIsFetchingAllGroup] = useState(true);
  const [isFetchingGroup, setIsFetchingGroup] = useState(true);
  const [isFetchingTable, setIsFetchingTable] = useState(true);
  const [errorGroup, setErrorGroup] = useState(undefined);
  const [errorTable, setErrorTable] = useState(undefined);
  const [enableRenderTable, setEnableRenderTable] = useState(false);

  //DEPRECATED: "Unable to load data" toast message was replaced with error message in the page.
  // useEffect(() => {
  //   if (errorTable || errorGroup) {
  //     useToast.addToast({
  //       id: 'retry',
  //       type: 'negative',
  //       text: t('unable_to_load_data'),
  //     });
  //   }
  // }, [errorTable, errorGroup]);

  // TODO: Set table refresh logic

  // API for setting allDevicesGroupUuid and ungroupedGroupUuid
  // It will be called only when user accessed devices MFE.
  useEffect(() => {
    if (allDevicesGroupUuid === '' && ungroupedGroupUuid === '') {
      apiGetAllGroups();
    } else {
      setIsFetchingAllGroup(false);
    }
  }, []);
  useEffect(() => {
    if (isFetchingAllGroup == false) {
      setIsFetchingGroup(true);
      apiGetGroups().finally(() => {
        setIsFetchingGroup(false);
      });
    }
  }, [isFetchingAllGroup]);

  useEffect(() => {
    if (isFetchingGroup == false) {
      setIsFetchingTable(true);
      setEnableRenderTable(false);

      apiGetTableResponse(mainGroupSelectedId)
        .then(({ devices, isError }) => {
          setTableColumns(tableId, columnConfig);
          handlePageData(devices);
          if (isError) {
            setErrorTable(isError);
          } else {
            setErrorTable(undefined);
          }
        })
        .finally(() => {
          setIsFetchingTable(false);
        });
    }
  }, [isFetchingGroup]);

  useEffect(() => {
    if (mainGroupRefresh) {
      apiGetGroups().finally(() => {
        dispatch(storeMainGroupRefresh(false));
        dispatch(storeDeviceTableRefresh(true));
      });
    }
  }, [mainGroupRefresh]);

  useEffect(() => {
    if (!deviceTableRefresh) {
      return;
    }

    apiGetTableResponse(mainGroupSelectedId)
      .then(({ devices, isError }) => {
        setTableColumns(tableId, columnConfig);
        handlePageData(devices);
        if (isError) {
          setErrorTable(isError);
        } else {
          setErrorTable(undefined);
        }
      })
      .finally(() => {
        dispatch(storeDeviceTableRefresh(false));
      });
  }, [deviceTableRefresh]);

  useEffect(() => {
    let completeGetTable = false;
    let completeGetGroup = false;

    completeGetGroup = isFetchingGroup == false && (errorGroup == undefined || isWex);
    completeGetTable = isFetchingTable == false && (errorTable == undefined || isWex);

    setEnableRenderTable(completeGetGroup && completeGetTable);
  }, [isFetchingGroup, isFetchingTable]);

  const handlePageData = (data) => {
    let items;
    if (data !== undefined) {
      items = JSON.parse(JSON.stringify(data));
    }

    if (searchItem) {
      const validResult = triggerSearch(items, searchItem);
      if (validResult) {
        items = validResult.pagedItems;
        dispatch(storeTotalDeviceCountInGroup(validResult.searchedItemsCount));
      }
    }

    const res = tableHandler.getTableData(items);
    dispatch(storeTableData(res));
  };

  // To get group uuids
  const apiGetAllGroups = async () => {
    console.log('[devices] apiGetAllGroups');
    setIsFetchingAllGroup(true);

    const { response: allGroupInfo, error: allGroupInfoError } = await getCollections(0, 1, 'All');
    const { response: ungroupedInfo, error: ungroupedInfoError } = await getCollections(
      0,
      1,
      'Ungrouped',
    );

    let newAllDevicesGroupUuid, newUngroupedGroupUuid;
    if (allGroupInfoError == undefined && ungroupedInfoError == undefined) {
      newAllDevicesGroupUuid = allGroupInfo.contents[0].id;
      newUngroupedGroupUuid = ungroupedInfo.contents[0].id;

      dispatch(storeAllDevicesGroupUuid(newAllDevicesGroupUuid));
      dispatch(storeUngroupedGroupUuid(newUngroupedGroupUuid));
      dispatch(storeMainGroupSelectedId(newAllDevicesGroupUuid));
      setIsFetchingAllGroup(false);
      return newAllDevicesGroupUuid;
    }

    dispatch(storeAllDevicesGroupUuid(''));
    dispatch(storeUngroupedGroupUuid(''));
    setErrorGroup(true);
    setErrorTable(true);
    setIsFetchingAllGroup(false);
    setIsFetchingGroup(false);
    setIsFetchingTable(false);
  };

  // To get group data
  const apiGetGroups = async () => {
    console.log('[devices] apiGetGroups');
    await getGroups(getCollections, setGroups, setErrorGroup);
  };

  const setGroups = (newGroups) => {
    dispatch(storeMainGroupData(newGroups));
    const count = newGroups.filter((group) => group.id === mainGroupSelectedId);
    dispatch(storeTotalDeviceCountInGroup(count[0].devices ?? 0));
  };

  const apiGetTableResponse = async (_mainGroupSelectedId) => {
    console.log(`[devices] apiGet Table Response`);

    let reqParam = cfgApiRequestOptions;

    if (cfgApiRequestOptions.search) {
      reqParam = { ...cfgApiRequestOptions, ...fullListOption };
    }

    const { response, error } = await getTypedCollectionById(_mainGroupSelectedId, reqParam);

    if (error != undefined) {
      return { devices: [], isError: true };
    }

    return {
      devices: response?.contents,
      isError: false,
    };
  };

  const memoRenderComponent = useMemo(() => {
    if (
      (!isFetchingAllGroup && !isFetchingGroup && !isFetchingTable && isWex) ||
      (enableRenderTable && enableRnPm)
    ) {
      console.log('[devices] render <DeviceMainTemplate />');
      return (
        <DeviceMainTemplate
          errorTable={errorTable}
          loadingTable={isFetchingTable}
          errorGroup={errorGroup}
          columnConfig={columnConfig}
          setShowEditGroupModal={props.setShowEditGroupModal}
        />
      );
    } else {
      return <></>;
    }
  }, [enableRenderTable]);

  return <>{isFetchingGroup || isFetchingTable ? <CommonLoading /> : <>{memoRenderComponent}</>}</>;
};

export default DeviceMainPage;
