import 'src/styles/global.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { setProps } from 'src/utils/commonMethods';
import { useConstructor } from 'src/hooks/useConstructor';
import { useStoreState } from 'src/store/useStoreState';
import { storeIsFromDetails } from 'src/store/devices/action';
import { getDevicesStoreActions } from 'src/store/devices/init';
import { getDevicesRnPmActions } from 'src/store/devicesRnPm/init';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { SubMfeDeviceDetails } from 'src/components/molecule';
import { DeviceMainPage, EditGroupModalPage, DeviceAppConfigPage } from 'src/components/page';

const App = (props: any) => {
  const { devicesState } = useStoreState();
  const { startRootComponent, isDevicesTab } = devicesState;
  const {
    isWex,
    isFromApps,
    navigation,
    accessControl,
    customRelativePath,
    deviceDetailsComponent,
  } = useShellRootContext();
  const pathName = isFromApps ? '/solutions/app' : customRelativePath;
  const baseName = isWex ? '' : navigation.createHref({ pathname: pathName });
  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);

  // init all redux stores
  useConstructor(() => {
    setProps();

    dispatch(storeIsFromDetails(false));
    getDevicesRnPmActions(accessControl, dispatch).finally(() => {
      dispatchAll(getDevicesStoreActions(props));
    });
  });

  useEffect(() => {
    if (props.forceRender != undefined) {
      dispatch(storeIsFromDetails(false));
      getDevicesRnPmActions(accessControl, dispatch).finally(() => {
        dispatchAll(getDevicesStoreActions(props));
      });
    }
  }, [props.forceRender]);

  const renderDeviceDetailsComponent = () => {
    return deviceDetailsComponent !== null ? (
      deviceDetailsComponent
    ) : (
      <SubMfeDeviceDetails {...props} {...{ customRelativePath }} />
    );
  };

  const didReduxUpdated = () => startRootComponent && isDevicesTab !== null;

  if (!didReduxUpdated()) {
    return <></>;
  }

  return (
    <Router basename={baseName}>
      <Switch>
        <Route exact path="/:appID/devices">
          <DeviceAppConfigPage />
        </Route>
        <Route exact path="/:appID/devices/:devID">
          {renderDeviceDetailsComponent()}
        </Route>

        <Route
          exact
          path="/(|devices|ui/view/devicesPrinter|view/devicesPrinter|ui/fleet-management/devicesPrinter|fleet-management/devicesPrinter)"
        >
          <DeviceMainPage {...props} setShowEditGroupModal={setShowEditGroupModal} />
          {showEditGroupModal && (
            <EditGroupModalPage
              showModal={showEditGroupModal}
              setShowModal={setShowEditGroupModal}
            />
          )}
        </Route>
        <Route path="(|/ui/view/devicesPrinter/|/view/devicesPrinter/|/):devID">
          {renderDeviceDetailsComponent()}
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
