import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { t } from 'src/utils/commonMethods';
import { Button } from '@veneer/core';
import { WarningMsg } from 'src/components/atom';
import { GroupTextBox } from 'src/components/molecule';
import { collectionAPI } from 'src/api/collection';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TEditGroupRenameProps = {
  groupData: any[];
  allDevicesGroupUuid: string;
  selectedGroupsIdList: string[];
  selectedGroupName: string;
  refreshEditGroupModalPage: () => void;
  setSelectedGroupsIdList: (e) => void;
  setSelectedGroupName: (e) => void;
  setActionCount: (e) => void;
};

const EditGroupRename = (props: TEditGroupRenameProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [enableRenameButton, setEnableRenameButton] = useState(false);
  const [groupNameSatisfied, setGroupNameSatisfied] = useState(false);
  const disableTextBox = props.selectedGroupsIdList.length != 1;
  const [refreshGroupTextBox, setRefreshGroupTextBox] = useState(true);
  const { useToast } = useShellRootContext();
  const { patchCollectionById } = collectionAPI();

  useEffect(() => {
    if (groupNameSatisfied && checkOnlyOneItemGroupTree() && !disableTextBox) {
      setEnableRenameButton(true);
    } else {
      setEnableRenameButton(false);
    }
  }, [groupNameSatisfied, groupName]);

  useEffect(() => {
    if (!disableTextBox) {
      setRefreshGroupTextBox(false);
      setTimeout(() => {
        setGroupName(props.selectedGroupName);
        setRefreshGroupTextBox(true);
      }, 100);
    }
  }, [props.selectedGroupName]);

  const handleRename = async () => {
    // TBD
    const { error } = await patchCollectionById(filteredDeviceGroupId(), groupName);

    if (error) {
      useToast.addToast({
        id: 'renameGroupFail',
        type: 'negative',
        text: t('group.rename_group_fail_msg', { newGroupName: groupName }),
      });
    } else {
      useToast.addToast({
        id: 'renameGroupSuccess',
        type: 'positive',
        text: t('group.rename_group_success_msg', { newGroupName: groupName }),
      });
    }
  };

  const filteredDeviceGroupId = () => {
    const allDeviceGroupId = [props.allDevicesGroupUuid, ''];
    return props.selectedGroupsIdList.filter((item) => !allDeviceGroupId.includes(item));
  };

  const checkOnlyOneItemGroupTree = () => {
    const filteredValue = filteredDeviceGroupId();
    return filteredValue.length === 1;
  };

  const renderGroupTextBox = () => {
    const description = t('group.rename_description');

    return (
      <CustomGroupTextBox disable={disableTextBox}>
        <GroupTextBox
          {...{
            groups: props.groupData,
            groupName,
            description,
            setGroupName,
            setGroupNameSatisfied,
            isSelectingGroupNeeded: true,
            selectedGroupName: props.selectedGroupName,
          }}
        />
      </CustomGroupTextBox>
    );
  };

  return (
    <Wrapper data-testid={'edit-group-rename'}>
      {disableTextBox && <WarningMsg description={t('group.selectGroup_warn')} />}

      {refreshGroupTextBox && renderGroupTextBox()}

      <SavePanel>
        <Button
          data-testid="edit-group-modal-rename-tab-action-button"
          appearance="primary"
          small={true}
          onClick={() => {
            setIsFetching(true);
            props.setActionCount((prevCount: number) => {
              return prevCount + 1;
            });
            handleRename().finally(() => {
              setIsFetching(false);
              props.refreshEditGroupModalPage();
            });
          }}
          disabled={!enableRenameButton}
          loading={isFetching}
        >
          {t('group.rename')}
        </Button>
      </SavePanel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px 24px 44px 24px;
`;

const CustomGroupTextBox = styled.div`
  .vn-input {
    width: 397px;

    ${(props) => {
      if (props.disable) {
        return `
        pointer-events: none;
        opacity: 0.5;
      `;
      }
    }}
  }
`;

const SavePanel = styled.div`
  display: flex;
  justify-content: right;
  position: absolute;
  right: 30px;

  > button {
    padding: 8px 20px;
    min-width: min-content;
  }
`;

export default EditGroupRename;
