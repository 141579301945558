import React from 'react';
import styled from 'styled-components';
import { escapeRegExp } from 'lodash';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TTableHighlightedTextProps = {
  value: string;
  searchItem: string;
};

const TableHighlightedText = (props: TTableHighlightedTextProps) => {
  const { isWex } = useShellRootContext();
  const getTextWithHighlights = () => {
    const regex = new RegExp(`(${escapeRegExp(props.searchItem)})`, 'gi');
    const parts = props.value.split(regex);
    return (
      <span>
        {parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <HighlightStyle isWex={isWex} key={i}>
                {part}
              </HighlightStyle>
            ) : (
              <span key={i}>{part}</span>
            ),
          )}
      </span>
    );
  };

  if (!props.searchItem.trim()) {
    return <span>{props.value}</span>;
  }

  return <>{getTextWithHighlights()}</>;
};

const HighlightStyle = styled.span`
  background: ${({ isWex }) => (isWex ? '#dbddff' : '#9fe0fe')};
`;

export default TableHighlightedText;
