import React, { useEffect, useState } from 'react';
import { appDeployAPI } from 'src/api/appDeploy';
import { TDownloadStatus } from 'src/types/TDownloadStatus';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { PopupWindow, DownloadAppConfigContent } from 'src/components/molecule';

type TDownloadAppConfigWindowProps = {
  setOpenNewWindow: (open: boolean) => void;
  selectedTableData: any;
};

const DownloadAppConfigWindow = (props: TDownloadAppConfigWindowProps) => {
  const [close, setClose] = useState(false);
  const [completedCount, setCompletedCount] = useState(0);
  const [downloadStatus, setDownloadStatus] = useState<TDownloadStatus>('initial');
  const [apiCallCount, setApiCallCount] = useState(0);
  const [requestId, setRequestId] = useState('');
  const [completedData, setCompletedData] = useState(null);

  const { appUuid } = useShellRootContext();
  const { getAppList, postAppConfig } = appDeployAPI();
  const [progressCntForPreRender, setProgressCntForPreRender] = useState(0);
  const MAX_CALL_COUNT = 5; // 5 sec

  // API call to get app configs & polling
  useEffect(() => {
    if (apiCallCount === 0) {
      apiPostAppConfig();
      return;
    }

    if (apiCallCount === MAX_CALL_COUNT) {
      setDownloadStatus('error_fail_to_collect');
    } else {
      apiGetAppList();
    }
  }, [apiCallCount]);

  useEffect(() => {
    if (requestId) {
      setApiCallCount(1);
      setDownloadStatus('downloading');
    }
  }, [requestId]);

  const apiPostAppConfig = async () => {
    const reqOptions = props.selectedTableData.map((device) => ({
      operation: 'read',
      deviceId: device.deviceId,
      appUuid: appUuid,
    }));

    try {
      const { response } = await postAppConfig(reqOptions);
      setRequestId(response.requestId);
    } catch (e) {
      setDownloadStatus('error_unable_to_download');
      console.log('[devices] download app config post api has failed', e);
    }
  };

  const apiGetAppList = async () => {
    console.log('[devices] apiGetAppList');

    const { response } = await getAppList(undefined, undefined, requestId);

    const _completedCount = response.apps.filter((app) => app.configState == 'READ').length;

    setCompletedCount(_completedCount);
    setCompletedData(response.apps);

    if (_completedCount < props.selectedTableData.length) {
      setApiCallCount(apiCallCount + 1);
    }
  };

  useEffect(() => {
    if (progressCntForPreRender < props.selectedTableData.length + 1) {
      setProgressCntForPreRender(progressCntForPreRender + 1);
    }
  }, [progressCntForPreRender]);

  return (
    <>
      <DownloadAppConfigContent
        data-testid="download-app-config-content-pre-rendered"
        status={downloadStatus}
        completed={progressCntForPreRender}
        deviceCount={props.selectedTableData.length}
        data={completedData}
        handleClose={() => {
          setClose(true);
        }}
        isPreRender={true}
      />
      {progressCntForPreRender == props.selectedTableData.length + 1 && (
        <PopupWindow
          {...props}
          title={'Preparing to Download'}
          id={'download-app-config-window'}
          close={close}
          setClose={setClose}
          status={downloadStatus}
        >
          <DownloadAppConfigContent
            data-testid="download-app-config-content-rendered"
            status={downloadStatus}
            completed={completedCount}
            deviceCount={props.selectedTableData.length}
            data={completedData}
            /* istanbul ignore next */
            handleClose={() => {
              setClose(true);
            }}
            isPreRender={false}
          />
        </PopupWindow>
      )}
    </>
  );
};

export default DownloadAppConfigWindow;
