import { storeStartRootComponent, storeIsDevicesTab } from 'src/store/devices/action';

export const getDevicesStoreActions = (props) => {
  return [
    storeStartRootComponent(true),
    storeIsDevicesTab(
      props.customRelativePath === null ||
        props.customRelativePath === undefined ||
        props.customRelativePath === '/devices',
    ),
  ];
};
