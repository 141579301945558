import { useDispatch } from 'react-redux';
import { storeMainGroupRefresh } from 'src/store/devicesGroup/action';
import { storeDeviceTableRefresh } from 'src/store/devices/action';

export const useRefreshHook: any = () => {
  const dispatch = useDispatch();

  const refreshDevicesTable = () => {
    dispatch(storeDeviceTableRefresh(false));
    setTimeout(() => dispatch(storeDeviceTableRefresh(true)), 100);
  };

  const refreshPage = () => {
    dispatch(storeMainGroupRefresh(true));
  };

  return {
    refreshPage,
    refreshDevicesTable,
  };
};
