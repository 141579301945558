import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { MfeLoader } from 'src/components/atom';
import { useShellRootContext } from 'src/contexts/ShellRoot';

const SubMfeDeviceDetailsConfig = (props: any) => {
  const { stack, shell, localization, authProvider, useToast } = useShellRootContext();
  const showMultipleConfigUi = true;

  const SubMfe = useCallback(
    ({ component, type }) => {
      return (
        <Wrapper>
          <MfeLoader
            disableContainerPadding
            {...props}
            {...{
              component,
              type,
              stack,
              shell,
              localization,
              authProvider,
              useToast,
              showMultipleConfigUi,
            }}
          />
        </Wrapper>
      );
    },
    [shell, stack, props],
  );

  const renderSubMfe = useMemo(() => {
    return (
      <div className="sub-mfe-device-details-Configuration">
        <SubMfe
          type="ECPDeviceDetailsConfiguration"
          component="@jarvis/react-ecp-device-details-configuration"
        />
      </div>
    );
  }, [props.showModal]);

  return <>{renderSubMfe}</>;
};

const Wrapper = styled.div`
  height: 40px;
  width: 227px;
  z-index: 1301;
`;

SubMfeDeviceDetailsConfig.defaultProps = {
  shell: {},
  stack: null,
};

SubMfeDeviceDetailsConfig.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  stack: PropTypes.number,
};

export default SubMfeDeviceDetailsConfig;
