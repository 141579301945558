import React from 'react';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import {
  getAppNames,
  getGroupsText,
  getAssessmentText,
  getConnectionStateText,
} from 'src/utils/textConverterMethods';
import {
  TableEllipsisTooltip,
  TableStatusRenderer,
  TableModelNameRenderer,
  TableAssessmentRenderer,
  TableConnectivityRenderer,
} from 'src/components/atom';
import { getStatus } from 'src/utils/commonMethods';

const TableRenderers = () => {
  const { setBreadcrumb, customRelativePath } = useShellRootContext();
  const { devicesState } = useStoreState();
  const { searchItem } = devicesState;

  // Renderer Component should contain "value" props.
  const text = (id, value) => (
    <TableEllipsisTooltip id={id} value={value} searchItem={searchItem} />
  );

  /* NOTE: value must be "string" type in order to be searched*/
  const getTableRenderers = (devicesData) => {
    return devicesData?.map((device) => ({
      ...device,
      objectUniqueKey: device.deviceId,
      modelName: (
        <TableModelNameRenderer
          value={device.identity.makeAndModel.name}
          textComponent={text('modelName', device.identity.makeAndModel.name)}
          deviceId={device.deviceId}
          addedDate={device.timestamp.firstRegistrationTime}
          setBreadcrumb={setBreadcrumb}
          customRelativePath={customRelativePath}
        />
      ),
      apps: text('app-renderer', getAppNames(device.software.apps)),
      status: <TableStatusRenderer value={getStatus(device.status)} />,
      connectionState: (
        <TableConnectivityRenderer value={getConnectionStateText(device.status.connectionState)} />
      ),
      connectivityTypes: text('connectivityTypes', device.connTypes),
      serialNumber: text('serialNumber', device.identity.serialNumber),
      addedDate: text('addedDate', device.timestamp.firstRegistrationTime),
      assessment: (
        <TableAssessmentRenderer
          value={getAssessmentText(device.solutionMetadata?.[0]?.result)}
          type={device.solutionMetadata?.[0]?.result}
        />
      ),
      assetNumber: text('assetNumber', device.identity.assetNumber),
      firmwareVersion: text('firmwareVersion', device.identity.firmwareVersion),
      group: text('group', getGroupsText(device.groups)),
      //hostname: device.identity.serialNumber,
      lastSyncTime: text('lastSyncTime', device.lastUpdatedAt),
      location: text('location', device.identity.location),
      // policies: text('policies', getPolicyText(device.solutionMetadata?.[0])),
      // policyLastRun: text('policyLastRun', device.solutionMetadata?.[0]?.lastAssessed ?? '--'),
      deviceName: text('deviceName', device.identity.friendlyName),
      wiredIPv4: text('wiredIPv4', device.network.adapters[0].ipv4.address.ip),
      wiredIPv6: text('wiredIPv6', device.network.adapters[0].ipv6.address.ip),
      wiredMAC: text('wiredMAC', device.network.adapters[0].macAddress.replaceAll(':', '')),
      wiredHostname: text('wiredHostname', device.network.adapters[0].hostname),
      wifiIPv4: text('wifiIPv4', device.network.adapters[1].ipv4.address.ip),
      wifiIPv6: text('wifiIPv6', device.network.adapters[1].ipv6.address.ip),
      wifiMAC: text('wifiMAC', device.network.adapters[1].macAddress.replaceAll(':', '')),
      wifiHostname: text('wifiHostname', device.network.adapters[1].hostname),
      productNumber: device.identity.makeAndModel.number,
      deviceId: device.deviceId,
      accountId: device.ownership.accountId,
      solutions: device.solutions,
    }));
  };

  return {
    getTableRenderers,
  };
};

export default TableRenderers;
