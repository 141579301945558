import styled, { css } from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useState } from 'react';
import EditGroupCreate from './EditGroupCreate';
import EditGroupRename from './EditGroupRename';
import { t } from 'src/utils/commonMethods';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';

type TGroupActionsProps = {
  groupData: any[];
  allDevicesGroupUuid: string;
  selectedGroupsIdList: string[];
  selectedGroupName: string;
  parentGroupList: any[];
  refreshEditGroupModalPage: () => void;
  setSelectedGroupsIdList: (e) => void;
  setSelectedGroupName: (e) => void;
  setActionCount: (e) => void;
};

const EditGroupActions = (props: TGroupActionsProps) => {
  const { isWex } = useShellRootContext();
  const { devicesRnPmState } = useStoreState();
  const {
    collectionsCreateRnPm,
    collectionsUpdateRnPm,
    contentsDevicesCreateRnPm,
    contentsCollectionsCreateRnPm,
  } = devicesRnPmState;
  const [activatedMenu, setActivatedMenu] = useState('create');

  const renderMenu = () => {
    switch (activatedMenu) {
      case 'create':
        return (
          collectionsCreateRnPm &&
          contentsDevicesCreateRnPm &&
          contentsCollectionsCreateRnPm && <EditGroupCreate {...props} />
        );
      case 'rename':
        return collectionsUpdateRnPm && <EditGroupRename {...props} />;
    }
  };

  return (
    <Wrapper>
      <TabHeaderWrapper>
        {collectionsCreateRnPm && contentsDevicesCreateRnPm && contentsCollectionsCreateRnPm && (
          <TabHeader
            isWex={isWex}
            data-testid="edit-group-modal-create-tab-header"
            onClick={() => {
              setActivatedMenu('create');
            }}
            activatedMenu={activatedMenu}
            currentMenu="create"
          >
            {t('group.create')}
          </TabHeader>
        )}
        {collectionsUpdateRnPm && (
          <TabHeader
            isWex={isWex}
            data-testid="edit-group-modal-rename-tab-header"
            onClick={() => {
              setActivatedMenu('rename');
            }}
            activatedMenu={activatedMenu}
            currentMenu="rename"
          >
            {t('group.rename')}
          </TabHeader>
        )}
      </TabHeaderWrapper>

      <TabBody>{activatedMenu && renderMenu()}</TabBody>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  background-color: #ffffff;
  color: #212121;
  border-radius: 16px;
  box-sizing: inherit;
  box-shadow: 0 4px 16px rgb(33 33 33 / 10%);
  border: 1px solid #ffffff;
  min-height: 350px;
  height: fit-content;
`;

const TabHeaderWrapper = styled.div`
  padding: 0 15px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  border-bottom: 2px solid transparent;
  box-sizing: inherit;
  height: 50px;
  box-shadow: inset 0px -1px 0px rgba(33, 33, 33, 0.1);
  border-radius: 12px 12px 0 0;
`;

const TabHeader = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 5px 15px;
  font-weight: bold;
  width: 106px;

  ${(props) => {
    if (props.activatedMenu !== props.currentMenu) {
      return css`
        border-bottom: 3px solid transparent;
        color: ${tokens.color.gray10};

        :hover {
          text-decoration: none;
          color: ${(props) => (props.isWex ? tokens.color.darkNavy6 : tokens.color.hpBlue6)};
          border-bottom: 3px solid
            ${(props) => (props.isWex ? tokens.color.darkNavy6 : tokens.color.hpBlue6)};
          transition: 1s;
        }

        :active {
          color: ${(props) => (props.isWex ? tokens.color.darkNavy4 : tokens.color.hpBlue4)};
          border-bottom: 3px solid
            ${(props) => (props.isWex ? tokens.color.darkNavy6 : tokens.color.hpBlue6)};
        }
      `;
    } else {
      return css`
        text-decoration: none;
        color: ${(props) => (props.isWex ? tokens.color.darkNavy6 : tokens.color.hpBlue6)};
        border-bottom: 3px solid
          ${(props) => (props.isWex ? tokens.color.darkNavy6 : tokens.color.hpBlue6)};
      `;
    }
  }}
`;

const TabBody = styled.div`
  position: relative;
`;

export default EditGroupActions;
