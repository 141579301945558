import styled from 'styled-components';
import tokens from '@veneer/tokens';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { t } from 'src/utils/commonMethods';
import { TextBox } from '@veneer/core';
import { WarningMsg } from 'src/components/atom';
import {
  isInvalidGroupName,
  isDuplicatedGroupName,
  isOutScopeLengthGroupName,
  isStartWithSpaceGroupName,
} from 'src/utils/validationMethods';

type TGroupTextBoxProps = {
  groups: any;
  groupName: string;
  description: string;
  setGroupName: (string) => void;
  setGroupNameSatisfied: (boolean) => void;
  isSelectingGroupNeeded?: boolean;
  selectedGroupName?: string;
};

const GroupTextBox = (props: TGroupTextBoxProps) => {
  const [enableInvalidMsg, setEnableInvalidMsg] = useState(false);
  const [enableDuplicateMsg, setEnableDuplicateMsg] = useState(false);
  const invalidErrorMsg = t('group.group_name_invalid_error');

  useEffect(() => {
    // for fixed initial option
    handleTextBoxChange(props.groupName, null);
  }, []);

  const handleTextBoxChange = (
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (isOutScopeLengthGroupName(value)) {
      props.setGroupNameSatisfied(false);
      setEnableInvalidMsg(false);
      setEnableDuplicateMsg(false);
      return;
    }

    if (isDuplicatedGroupName(value, props.groups)) {
      props.setGroupName(value);
      props.setGroupNameSatisfied(false);
      setEnableInvalidMsg(false);
      setEnableDuplicateMsg(true);
      return;
    }

    if (isInvalidGroupName(value)) {
      props.setGroupNameSatisfied(false);
      setEnableInvalidMsg(true);
      return;
    }

    if (isStartWithSpaceGroupName(value)) {
      props.setGroupNameSatisfied(false);
      return;
    }

    setEnableInvalidMsg(false);
    setEnableDuplicateMsg(false);

    // happy path
    props.setGroupName(value);
    props.setGroupNameSatisfied(true);
  };

  return (
    <Wrapper data-testid="group-text-box" className="group-text-box-wrapper">
      {props.isSelectingGroupNeeded == true &&
      props.selectedGroupName != undefined &&
      props.selectedGroupName == '' ? (
        <></>
      ) : (
        enableDuplicateMsg &&
        !enableInvalidMsg && (
          <WarningMsg
            description={`${t('group.duplicate_warn', { groupName: props.groupName })}`}
          />
        )
      )}

      <Description data-testid="group-text-box-description">{props.description}</Description>
      <Label data-testid="group-text-box-name-label">{t('group.group_name')}</Label>

      <TextBoxWrapper>
        <TextBox
          id="text-box"
          data-testid="group-text-box-input"
          placeholder={t('group.group_name_textbox')}
          defaultValue={props.groupName}
          onChange={handleTextBoxChange}
          helperText={enableInvalidMsg ? invalidErrorMsg : ''}
          error={enableDuplicateMsg || enableInvalidMsg}
        />
      </TextBoxWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const Description = styled.div`
  width: 100%;
  font-style: normal;
  font-size: 18px;
`;

const Label = styled.div`
  margin-top: 16px;
  font-style: normal;
  //font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  ::after {
    content: '*';
    margin-left: 5px;
    color: ${tokens.color.red5};
    font-weight: bold;
`;

const TextBoxWrapper = styled.div`
  margin-top: 3px;
`;

export default GroupTextBox;
