import { service } from 'src/utils/constants';
import { useApiClient } from 'src/api/useApiClient';
import { refinedFetchAppList } from 'src/api/appDeploy/appDeployRefinery';
import { isMockUpMode } from 'src/utils/commonMethods';

import getAppListMock from 'src/mock/appDeploy/get-applist.json';

/**
 * App Deploy API
 */
export const appDeployAPI: any = () => {
  const { getApiClient } = useApiClient();
  const client = getApiClient(service.appDeploy);

  const getAppList = async (deviceId?, appUuid?, requestId?) => {
    let error, response: any;

    if (isMockUpMode()) {
      response = refinedFetchAppList(getAppListMock);

      // await getMockUpData('appDeploy/get-applist').then((data) => {
      //   response = refinedFetchAppList(data);
      // });
      return { response, error };
    }

    await client
      .getAppList({ deviceId, appUuid, requestId })
      .then((res) => {
        response = refinedFetchAppList(res.data);
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  const postAppConfig = async (apps) => {
    let error, response: any;

    await client
      .postAppConfig(apps)
      .then((res) => {
        response = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { response, error };
  };

  return {
    getAppList,
    postAppConfig,
  };
};
