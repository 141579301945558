import { store } from 'src/utils/constants';

/**
 *  Common
 */
export const storeAllDevicesGroupUuid = (_payload) => {
  return {
    type: store.devicesGroup.ALL_DEVICES_GROUP_UUID,
    payload: _payload,
  };
};

export const storeUngroupedGroupUuid = (_payload) => {
  return {
    type: store.devicesGroup.UNGROUPED_GROUP_UUID,
    payload: _payload,
  };
};

/**
 *  Main Group
 */
export const storeMainGroupData = (_payload) => {
  return {
    type: store.devicesGroup.MAIN_GROUP_DATA,
    payload: _payload,
  };
};

export const storeMainGroupSelectedId = (_payload) => {
  return {
    type: store.devicesGroup.MAIN_GROUP_SELECTED_ID,
    payload: _payload,
  };
};

export const storeMainGroupSelectedName = (_payload) => {
  return {
    type: store.devicesGroup.MAIN_GROUP_SELECTED_NAME,
    payload: _payload,
  };
};

export const storeMainGroupRefresh = (_payload) => {
  return {
    type: store.devicesGroup.MAIN_GROUP_REFRESH,
    payload: _payload,
  };
};

/**
 *  Move Group Modal
 */
export const storeMoveGroupModalData = (_payload) => {
  return {
    type: store.devicesGroup.MOVE_GROUP_MODAL_DATA,
    payload: _payload,
  };
};

export const storeMoveGroupModalSelectedId = (_payload) => {
  return {
    type: store.devicesGroup.MOVE_GROUP_MODAL_SELECTED_ID,
    payload: _payload,
  };
};

export const storeMoveGroupModalSelectedName = (_payload) => {
  return {
    type: store.devicesGroup.MOVE_GROUP_MODAL_SELECTED_NAME,
    payload: _payload,
  };
};

/**
 *  Reset Device Group Reducer
 */
export const storeDeviceGroupReducerResetState = () => {
  return {
    type: store.devicesGroup.RESET_DEVICE_GROUP_REDUCER,
  };
};

/**
 *  Responsive Group
 */
export const storeFlexShowMainGroupModal = (_payload) => {
  return {
    type: store.devicesGroup.FLEX_SHOW_MAIN_GROUP_MODAL,
    payload: _payload,
  };
};

/**
 *  WEX
 */
export const storeShowSideBar = (_payload) => {
  return {
    type: store.devicesGroup.SHOW_SIDE_BAR,
    payload: _payload,
  };
};
